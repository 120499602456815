import React, { useEffect, useState } from 'react';
import axios from '../../../Config/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { alertMessage } from '../../../Functions/Functions';
import { getUserToken } from '../../../Session/Session';
import parse from 'html-react-parser';
import './PaidExamModule.css';
import { Col, Container, Row } from 'react-bootstrap';
import Loader from '../../../Loader/Loader';
import Countdown from 'react-countdown';
import BreadcrumbComponent from '../../../BreadcrumbComponent/BreadcrumbComponent';
const PaidExamModule = () => {
    const [questions,setQuestions]      = useState([]);
    const [topic,setTopic]              = useState({});
    const [isModule, setIsModule]       = useState(true);
    const [answers,setAnswers]          = useState([]);
    const [selectedQ,setSelectedQ]      = useState([]);
    const [isSubmit,setIsSubmit]        = useState(false);
    const [startTime,setStartTime]      = useState(new Date());
    const [time,setTime]                = useState(0);
    const [currentTime,setCurrentTime]  = useState(0);
    const [errors, setErrors]           = useState([]);
    const navigate              = useNavigate();
    const { id }                = useParams();
    const token                 = getUserToken();
    const [breadcrumbs,setBreadcrumbs]  = useState([
        {name : 'হোম',"url" : '/',active:false},
        {name : 'আমার পেইড পরীক্ষা',"url" : '/my-paid-exams',active:false},
    ]);
    const getModule = async () =>{
        if(token !== false)
        {
            try {
                const res = await axios.get(`/web/paid-exam-module/${id}`,{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                if(res.data.error === false)
                {
                    setBreadcrumbs([
                        ...breadcrumbs,
                        {name : res.data.data.exam_name,"url" : `/my-paid-exam/${res.data.data.exam_id}`,active:false},
                        {name : res.data.data.topic.name,"url" : `/my-paid-exam-topics/${res.data.data.topic.id}`,active:false},
                    ]);
                    setQuestions(res.data.data.questions);
                    setTopic(res.data.data.topic);
                    
                }else{
                    setIsModule(false);
                    alertMessage('error','Error',res.data.message);
                }
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }else{
            navigate('/login');
        }
        setIsModule(false);
    }
    useEffect(() => {
        document.title = "Paid Exam Module | Nahid24.com";
        getModule();
    },[]);
    const handelOptionChange = (e,question_id,answer_number) =>{
        e.preventDefault();
        const exist = selectedQ.includes(question_id);
        if(exist === false)
        {
            let tempSQArr = selectedQ;
                tempSQArr.push(question_id);
                setSelectedQ(tempSQArr);
                let tempAnswer = answers;
                tempAnswer.push(answer_number);
                // tempAnswer = [...answers, {id:question_id,answer:answer_number}];
                setAnswers(tempAnswer);
                e.target.classList.add("select-answer");
        }
    }
    
    const Completionist = () => {
        alertMessage('error','Error',"Times Up!");
        finishedExam();
    };

    const finishedExam = async () => {
        setIsSubmit(true);
        setErrors([]);
        let selectedAnswers = [];
        selectedAnswers = selectedQ.map((question_id,index) => {
            return {id:question_id,answer:answers[index]};
        })
        let start_time  = startTime;
        let end_time    = new Date();
        let exam_time   = Math.ceil(Math.abs(start_time.getTime() - end_time.getTime()) / 1000);
        try {
            const res = await axios.post(`/web/paid-exam-module-submit/${id}`,{answers:selectedAnswers,exam_time:exam_time},{
                headers: {
                  'Authorization': `Bearer ${token}` 
                }
              });
              if(res.data.error === false)
                {
                    alertMessage('success','Success','You Have Successfully Submitted Your Answer.')
                    navigate(`/paid-exam-score/${res.data.id}`)
                }else{
                    setErrors(res.data.message);
                }
              
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
        setIsSubmit(false);
    }
    const handelAnswerSubmit = async (e) => {
        e.preventDefault();
        setIsSubmit(true);
        setErrors([]);
        let selectedAnswers = [];
        selectedAnswers = selectedQ.map((question_id,index) => {
            return {id:question_id,answer:answers[index]};
        })
        let start_time  = startTime;
        let end_time    = new Date();
        let exam_time   = Math.ceil(Math.abs(start_time.getTime() - end_time.getTime()) / 1000);
        try {
            const res = await axios.post(`/web/paid-exam-module-submit/${id}`,{answers:selectedAnswers,exam_time:exam_time},{
                headers: {
                  'Authorization': `Bearer ${token}` 
                }
              });
              if(res.data.error === false)
                {
                    alertMessage('success','Success','You Have Successfully Submitted Your Answer.')
                    navigate(`/paid-exam-score/${res.data.id}`)
                }else{
                    setErrors(res.data.message);
                }
              
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
        setIsSubmit(false);
    }
    if(isModule) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    } 
    return (
        <Container className='my-2'>
            <BreadcrumbComponent breadcrumbs={breadcrumbs} />
            <div className="paid-exam-module-section my-3">
            
                {
                    questions.length > 0 ? 
                    <>
                        <div className="paid-exam-topbar">
                            <span>{topic.name}</span>
                            <span>
                                <Countdown date={Date.now() +  Math.floor((parseInt(topic.exam_time) * 60) * 1000)}>
                                    <Completionist />
                                </Countdown></span>
                        </div>
                        <div className="question-section mt-4">
                            <Row>
                                {
                                    questions.map((question,index) => {
                                        return(
                                        <Col md={12} className="mb-3" key={question.id}>
                                            <div className="question-box" id={`question-box-${question.id}`}>
                                                <div className='question-title'>
                                                    {question.ordering}. {parse(question.question)}
                                                </div>
                                                <div className='ps-2 options-box'>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-2">
                                                                <input type="checkbox" name={`answers[${question.id}]['answer']`} className="btn-check" id={`option_one_${question.id}_${parse(question.option_one)}`}  autoComplete="off" />
                                                                <label className="question-label" onClick={(e) => handelOptionChange(e,question.id,(1))} htmlFor={`option_one_${question.id}_${parse(question.option_one)}`}>{parse(question.option_one)}</label>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-2">
                                                                <input type="checkbox" name={`answers[${question.id}]['answer']`} className="btn-check" id={`option_two_${question.id}_${parse(question.option_one)}`}  autoComplete="off" />
                                                                <label className="question-label" onClick={(e) => handelOptionChange(e,question.id,(2))} htmlFor={`option_two_${question.id}_${parse(question.option_one)}`}>{parse(question.option_two)}</label>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-2">
                                                                <input type="checkbox" name={`answers[${question.id}]['answer']`} className="btn-check" id={`option_three_${question.id}_${parse(question.option_one)}`}  autoComplete="off" />
                                                                <label className="question-label" onClick={(e) => handelOptionChange(e,question.id,(3))} htmlFor={`option_three_${question.id}_${parse(question.option_one)}`}>{parse(question.option_three)}</label>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-2">
                                                                <input type="checkbox" name={`answers[${question.id}]['answer']`} className="btn-check" id={`option_four_${question.id}_${parse(question.option_one)}`}  autoComplete="off" />
                                                                <label className="question-label" onClick={(e) => handelOptionChange(e,question.id,(4))} htmlFor={`option_four_${question.id}_${parse(question.option_one)}`}>{parse(question.option_four)}</label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                        
                                    )})
                                }
                                
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {
                                        errors.length > 0 ? 
                                        <div className="alert alert-warning" role="alert">
                                            {errors.map((errorItem,index) => {return(<p key={index} className="text-danger text-start m-0">{errorItem}</p>)})}
                                        </div>
                                        : ''
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="text-center">
                                    {
                                        isSubmit ? <button className="btn btn-primary px-4" type="button"> <Loader/> </button> 
                                        : <button className="btn btn-primary px-4" type="button" onClick={handelAnswerSubmit}> Submit </button>
                                    }
                                </Col>
                            </Row>
                        </div> 
                    </>
                :
                    <div class="alert alert-warning text-center" role="alert">
                        Data not found!
                    </div>
                }
            </div>
        </Container>
    );
};

export default PaidExamModule;