import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../Config/axios';
import { alertMessage } from '../Functions/Functions';
import Loader from '../Loader/Loader';
import { getUserInfo, getUserToken, setUserInfo } from '../Session/Session';
import './Profile.css';
const Profile = () => {
    const userInfo                  = getUserInfo();
    const [errors, setErrors]       = useState([]);
    const [isLoading, setLoading]   = useState(false);
    const navigate                  = useNavigate();
    useEffect(()=>
    {
        document.title = "Profile | Nahid24.com"
        if(getUserToken() === false)
        {
            navigate('/login');
        }
    },[])
    const handelRegistration = async (e) =>{
        e.preventDefault();
        setLoading(true);
        setErrors('');
        const user = {
            name:e.target.name.value,
            email:e.target.email.value,
            number:e.target.phone.value,
            institute:e.target.institute.value,
        };
        const token = getUserToken();
        try {
            const res = await axios.put('/web/profile',user,{
                                    headers: {
                                        'Authorization': `Bearer ${token}` 
                                    }
                                });
            if(res.data.error === false)
            {
                setUserInfo(res.data.data);
                alertMessage('success','Success',res.data.message);
                navigate('/profile');
            }else{
                setErrors(res.data.message);
            }
        } catch (error) {
            alertMessage('error','Error',error.message)
        }
        setLoading(false);
    }
    return (
        <Container>
            <div className='register-section'>
                <h2 className="register-title mt-3"><span>My Profile</span></h2>
                <div className='register-from'>
                    <Row className="justify-content-center p-2">
                        <Col md={3} className="text-center">
                            <div><Link to="/my-order" className='profile-button btn btn-primary d-block mb-2'>My All Order</Link></div>
                            <div><Link to="/my-paid-exams" className='profile-button btn btn-primary  d-block mb-2'>My Paid Exam</Link></div>
                            <div><Link to="/my-paid-exam-results" className='profile-button btn btn-primary  d-block mb-2'>My Paid Exam Result</Link></div>
                            <div><Link to="/delete-account" className='profile-button btn btn-primary  d-block mb-2'>Delete Account</Link></div>
                            <div><Link to="/request-to-remove-data" className='profile-button btn btn-primary  d-block mb-2'>Request To Remove Data</Link></div>
                            {/* <div><Link to="/course-video" className='profile-button btn btn-primary  d-block mb-2'>Course</Link></div> */}
                        </Col>
                        <Col md={6}>
                            <div className="register-form-content">
                                <div className="register-image">
                                    {/* <img
                                        src={logo}
                                        width="80"
                                        className="d-inline-block align-top"
                                        alt="React Bootstrap logo"
                                        /> */}
                                </div>
                                <Form className="mt-5" onSubmit={handelRegistration}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Control required defaultValue={userInfo.name} name="name" type="text" placeholder="Enter Your Name" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Control required defaultValue={userInfo.email} name="email" type="email" placeholder="Enter Your Email" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="phone">
                                        <Form.Control name="number" type="text" defaultValue={userInfo.number} placeholder="Enter Your Phone Number" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="institute">
                                        <Form.Control required defaultValue={userInfo.institute} name="institute" type="text" placeholder="Enter Your Institute Name" />
                                    </Form.Group>
                                    {
                                        errors.length > 0 ? 
                                        <div className="alert alert-warning" role="alert">
                                            {errors.map((errorItem,index) => {return(<p key={index} className="text-danger text-start m-0">{errorItem}</p>)})}
                                        </div>
                                        : ''
                                    }
                                    <div className="text-end">
                                        {
                                            isLoading ? 
                                            <Button variant="primary" type="button">
                                                <Loader/>
                                            </Button>
                                            : 
                                        <Button variant="primary" type="submit" >
                                            Update
                                        </Button>
                                        }
                                        
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    );
};

export default Profile;