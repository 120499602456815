import React from 'react';
import {Navigate, useLocation,Outlet  } from 'react-router-dom';
import { getUserToken } from '../../Session/Session';

const PrivateRoute = () => {
    const location  = useLocation();
    return (
        getUserToken() ? <Outlet/> : <Navigate to="/login" state={{ state: location.pathname }} />
    )
};

export default PrivateRoute;