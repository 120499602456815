import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { alertMessage } from '../Functions/Functions';
import Loader from '../Loader/Loader';
import axios from '../Config/axios';
import parse from 'html-react-parser';
import { getUserToken } from '../Session/Session';
import './AllOrder.css';
const AllOrder = () => {

    const [orders,setOrders]            = useState([]);
    const [isOrders, setIsOrders]       = useState(true);
    const [paidExams,setPaidExams]      = useState([]);
    const [isPaidExam,setIsPaidExam]    = useState(true);
    const navigate                      = useNavigate();
    const getOrders = async () =>{
        if(getUserToken() !== false)
        {
            try {
                const token = getUserToken();
                const res = await axios.get('/order',{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                setOrders(res.data.data);
                setIsOrders(false);
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }else{
            navigate('/login');
        }
    }
    const getPaidExams = async () =>{
        try {
            const token = getUserToken();
            const res = await axios.get('/web/my-paid-exam-orders',{
                headers: {
                  'Authorization': `Bearer ${token}` 
                }
              });
              setPaidExams(res.data.data);
              setIsPaidExam(false);
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    useEffect(() => {
        document.title = "My Order | Nahid24.com";
        getOrders();
        getPaidExams();
    },[]);
    
    const handelMessage = () => {
        alertMessage('warning','Notice','Comming soon!')
    }
    const handelOrderStatus = async (e,id) =>{
        const status = e.target.value;
        if(status == 'Cancel')
        {
            try {
                const token = getUserToken();
                const res = await axios.get(`/web/cancel-order/${id}`,{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                  if(res.data.error === true)
                  {
                    alertMessage('error','Error',res.data.message);
                  }else{
                    alertMessage('success','Success',res.data.message);
                    window.location.reload();
                  }
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }
    }
    if (isOrders) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    }
    return (
        <Container>
            <div className="my-order-section">
                <div className='paid-course'>
                    <h2 className='order-title'>My Books Order</h2>
                    <div className="paid-course-container">
                        <div className='container-heading'>Order ID</div>
                        <div className='container-heading'>Receipt</div>
                        <div className='container-heading'>Status</div>
                        <div className='container-heading'>Track</div>
                    </div>
                    {
                        orders.map((order) => {
                            let order_item_status = '';
                            let order_item_status_color = 'text-warning';
                            if(order.status === 'Processing'){
                                order_item_status = `আপনার ${order.id} অর্ডারটি Processing অবস্থায় রয়েছে। Nahid24.com থেকে আপনাকে ফোন করে তার পর Approve করা হবে।`;
                            }else if(order.status === 'Approved'){
                                order_item_status_color = 'text-success';
                                order_item_status = `আপনার ${order.id} অর্ডারটি Approve হয়েছে। আশা করছি ১-৪ দিনের মধ্যেই বই পেয়ে যাবেন? -<a ${order?.payment?.traking ? 'target="_blank"' : '' } href=${order?.payment?.traking ? order?.payment?.traking : '#' }>Track Your Order</a>`;
                            
                            }else if(order.status === 'Hold'){
                                order_item_status = `আপনার ${order.id} এই নাস্বারে ফোন করা হয়েছিল। আপনার ফোন বন্ধ/ব্যস্ত ছিল। তাই order টি hold করা হয়েছে।`;
                            }else if(order.status === 'Accepted'){
                                order_item_status_color = 'text-info';
                                order_item_status = `আপনার ${order.id} অর্ডারটি Accept করা হয়েছে। শিপিং করার জন্য প্রস্তুত হচ্ছে।`;
                            }else if(order.status === 'Delivered'){
                                order_item_status_color = 'text-success';
                                order_item_status = `আপনার ${order.id} অর্ডারটি Delivery হয়ে গিয়েছে। আপনাকে অনেক ধন্যবাদ Nahid24 এর সাথে থাকার জন্য।`;
                            }else if(order.status === '3rd Party'){
                                order_item_status_color = 'text-success';
                                order_item_status = `আপনার ${order.id} অর্ডারটি আমরা একটি 3rd Party এর মাধ্যমে পাঠিয়েছি। আশা করছি ২-৫ দিনের মধ্যেই বই পেয়ে যাবেন।`;
                            }else if(order.status === 'Cancelled'){
                                order_item_status_color = 'text-danger';
                                order_item_status = `আপনার ${order.id} order টি Cancel হয়ে গিয়েছে। অর্ডারটি বাতিল হওয়ার কারণ হতে পারে : ১. আপনাকে - নম্বরে কল দিয়ে আমরা যোগাযোগ করতে পারিনি। ২. আপনাকে কল দেওয়ার পর আপনি নিজেই ক্যানসেল করে দিতে বলেছেন। ৩. আপনার - নম্বরটি Wrong Number ৪. আপনার - নম্বরে কল দিয়ে যিনি রিসিভ করেছেন তিনি বই অর্ডারের ব্যাপারে কিছুই জানেন না। অর্ডারটি পুনরায় চালু করতে যোগাযোগ করুন – 01787943429`;
                            }else if(order.status === 'Pre order'){
                                order_item_status_color = 'text-warning';
                                order_item_status = `আপনার অর্ডারটি Pre order হিসেবে নেওয়া হয়েছে। আপনার অর্ডারকৃত প্রডাক্টটি এখনো প্রস্তুত হয়নি। প্রস্তুত হলেই আপনার অর্ডারটি পাঠিয়ে দেওয়া হবে। Kindly একটু অপেক্ষা করুন।`;
                            }
                            return(
                                <>
                                    <div key={order.id} className="paid-course-container">
                                        <div className="container-value">{order.id}</div>
                                        <div className="container-value"><Link to={`/order-receipt/${order.id}`}>Click</Link></div>
                                        
                                        {
                                            order.status == 'Processing' ? 
                                            <select className='form-control processing-status' onChange={(e) => handelOrderStatus(e,order.id)}>
                                                <option value="Processing">Processing</option>
                                                <option value="Cancel">Cancel</option>
                                            </select>
                                            :

                                            <div className="container-value">{order.status}</div>
                                        }

                                        <div className="container-value" >
                                            <a target="_blank" href={order.traking}>Click</a>
                                        </div>
                                    </div>
                                    <p className={order_item_status_color} style={{padding:"6px 30px",fontSize:"20px"}}>{parse(order_item_status)}</p>
                                </>
                            )
                        })
                    }
                    
                </div>
                <div className='paid-course'>
                    <h2 className='order-title'>My Paid Exam Order</h2>
                    <div className="paid-course-container">
                        <div className='container-heading'>Order ID</div>
                        <div className='container-heading'>Receipt</div>
                        <div className='container-heading'>Status</div>
                        <div className='container-heading'>Paid Exam</div>
                    </div>
                    {
                        isPaidExam ? <Loader/> : 
                        paidExams.map((paidExam) => {
                            return(
                                <div key={paidExam.id} className="paid-course-container">
                                    <div className="container-value">PE{paidExam.id}</div>
                                    <div className="container-value"><Link to={`/paid-exam-order-receipt/${paidExam.id}`}>Click</Link></div>
                                    <div className="container-value">{paidExam.status}</div>
                                    <div className="container-value" >
                                        <Link to="/my-paid-exams">Click</Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
            <div className='videotutorial'>
                <Row className="videoContent">
                    <Col md={6}>
                        <div className='video'>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/GWrxfiDj5tM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='guid-content'>
                            <h2>কিভাবে Order Track করবেন?</h2>
                            <p>কিভাবে Order Track করবেন তা বুঝতে পারছেন না?</p>
                            <p>তাহলে পাশের Video Tutorial টি দেখতে পারেন। </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default AllOrder;