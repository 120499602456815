import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../../Config/axios';
import { alertMessage } from '../../../Functions/Functions';
import Loader from '../../../Loader/Loader';
import { getUserToken } from '../../../Session/Session';
import './MyPaidExamResults.css';
const MyPaidExamResults = () => {
    const [answers,setAnswers]          = useState([]);
    const [isAnswers, setIsAnswers]     = useState(true);
    const navigate                      = useNavigate();
    const token                         = getUserToken();
    const getAnswers = async () =>{
        if(token !== false)
        {
            try {
                const res = await axios.get(`/web/paid-exam-module-results`,{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                if(res.data.error === false)
                {
                    setAnswers(res.data.data);
                    setIsAnswers(false);
                }else{
                    setIsAnswers(false);
                    alertMessage('error','Error',res.data.message);
                }
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }else{
            navigate('/login');
        }
    }
    useEffect(() => {
        document.title = "Paid Exam Module Results | Nahid24.com";
        getAnswers();
    },[]);
    if(isAnswers) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    } 
    return (
        <Container className='my-3'>
            <div className="paid-exam-results-section">
                <Row>
                    <Col md={12} className="my-3"><h3>My Paid Exam Results</h3></Col>
                    <Col md={12}>
                        <div className="table-responsive">
                            <table className="table table-hover table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Subject</th>
                                        <th scope="col">Topic</th>
                                        <th scope="col">Total Marks</th>
                                        <th scope="col">Obtained Marks</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        answers.length > 0 ?
                                        answers.map((answer) => {
                                            return(
                                                <tr key={answer.id}>
                                                    <td>{answer.subject}</td>
                                                    <td>{answer.topic}</td>
                                                    <td>{answer.total_marks}</td>
                                                    <td>{answer.obtained_marks}</td>
                                                    <td>
                                                        <Link to={`/paid-exam-result/${answer.id}`} className=" btn btn-primary mb-1">Review Answer</Link>
                                                        <Link to={`/my-paid-exam-leader-board/${answer.id}`} className=" btn btn-primary mb-1 ms-1">Leader Board</Link>
                                                        <Link to={`/my-paid-exam-leader-board-all/${answer.id}`} className=" btn btn-primary mb-1 ms-1">Leader Board All</Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        
                                        :
                                        <tr>
                                            <th colSpan="6" className="text-center text-danger">Data not found!</th>
                                        </tr>
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default MyPaidExamResults;