import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './AboutUs.css';
import aboutUs  from '../../../images/about-us.png';
const AboutUs = () => {
    useEffect(() => {
        document.title = "About Us | Nahid24.com"
     }, []);
    return (
        <Container>
            <div className='about-section bg-white'>
                <div className='title-logo'>
                    <img src={aboutUs} />
                </div>
                <p>Nahid24 is a free online educational platform in Bangladesh. Here you will get all Video classes, Live classes for HSC, admission & job preparation. Nahid Hasan Munna Sir’s dream was to create a platform through which everyone can study for free at home. The Nahid24 Online education platform has been created to realize his dream. Here any student will be able to take exams on any subject for free and check his / her position. After taking the subject-based test, students will also be able to take the Model Test for final preparation for free!</p>
                <p>Although Nahid24 is currently one of the best educational online platforms in Bangladesh, but its initial journey has not been smooth. The founder of Nahid24 ‘Nahid Hasan Munna’ said to us that in 2015, when he was a college student, uploading educational videos on YouTube, many people used to make fun of him and question his qualifications. Even though his video was standard, he did not have a varsity tag, so he had to wear it in the face of various criticisms. After getting a chance at Rajshahi University, he gave a strong answer to all the critics. He has proven that his skills are enough to teach someone, not just get a chance at university.</p>
                <p>He could not explain to them that varsity tags are not enough to make educational videos. According to him, “The person who wants to teach something must have the knowledge inside himself and the must-have skill to present the subject in front of the students nicely and simply.</p>
                <p>He also said that when he asked a friend to grab the back camera of his mobile phone for the first video shoot, the friend grabbed him for 2 minutes and said, ‘Damn crazy, who will watch this video of yours!’ Nahid Hasan Munna said that one of his friends once joked to him, “How many 24 don’t get rice, and you are coming Nahid24!” Hundreds of omissions and criticisms have resulted in the realization of his Nahid24 dream. Today, the Nahid24 channel has surpassed 2 lakh subscribers, and this number is increasing every day.</p>
                <p>At last, he advised his audience that those critical friends now seek his suggestion on how to do better in life. This is the hard truth of life. When you try to do something good in life, others will try to pull you back down. Because they never want you to succeed in life. So, to be successful in life, according to Nahid Hasan Munnar, you should not lose your desire to work in the criticism of others. Turn criticism into strength, achieve something good in life, answer it, and prove yourself worthy. Force the critic to praise you.</p>
            </div>
        </Container>
    );
};

export default AboutUs;