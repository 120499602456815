import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import BreadcrumbComponent from '../BreadcrumbComponent/BreadcrumbComponent';
import { getCartItems, getUserInfo, getUserToken, removeCartItem } from '../Session/Session';
import axios from '../Config/axios';
import './Checkout.css';
import { alertHtmlMessage, alertMessage, notofication_forcash } from '../Functions/Functions';
import Loader from '../Loader/Loader';
const Checkout = () => {
    let TotalCartSum = 0;
    const userInfo                      = getUserInfo();
    const navigate                      = useNavigate();
    let [cartItems,setCartItems]        = useState([]);
    const [errors, setErrors]           = useState([]);
    const handleClose = ()              => setShow(false);
    const [show, setShow]               = useState(false);
    const [method,setMethod]            = useState('Payment');
    const [inputAddress,setinputAddress]    = useState('');
    const [numberHide,setNumberHide]    = useState('d-block');
    const [paymentType, setPaymentType] = useState(method);
    const [districts, setDistricts]     = useState([]);
    const [district, setDistrict]       = useState('');
    const [areas, setAreas]             = useState([]);
    const [orderLoading, setOrderLoading]   = useState(false);
    let [shipping,setShipping]          = useState(0);
    let [others,setOthers]              = useState(0);
    let [cash,setCash]                  = useState(0);
    let [discount,setDiscount]          = useState(0);
    const [coupon,setCoupon]            = useState('');    
    const [isApply,setIsApply]          = useState(false);  
    const [is_cash,setIsCash]           = useState(false);  
    const [products,setProducts]        = useState([]);
    const [breadcrumbs] = useState([
        {name : 'Home',"url" : '/',active:false},
        {name : 'Checkout',"url" : '/checkout',active:true},
    ]);
    if(getUserToken() === false)
    {
        navigate('/login')
    }
    const getShiping = async () =>{
        try {
            const res = await axios.get('/web/delivery-charge');
            setOthers(res.data.data.others.amount);
            setCash(res.data.data.cash.amount);
            setShipping(res.data.data.cash.amount);
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    const getDistrict = async () =>{
        try {
            const res = await axios.get('/district');
            setDistricts(res.data.data);
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    const getArea = async (id) =>{
        try {
            const res = await axios.get(`/sub-district/${id}`);
            setAreas(res.data.data);
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    const handelArea = (e) =>{
        let districId = e.target.value;
        let textContent = e.target.selectedOptions[0].text;
        if(districId != '')
        {
            setDistrict(textContent);
            getArea(districId)
        }else{
            setAreas([]);
        }
    }
    useEffect(()=>{
        document.title = "Checkout | Nahid24.com"
        setCartItems(getCartItems());
        getShiping();
        getDistrict();
        get_products(getCartItems());
    },[]);
    const get_products = async (items) =>{
        try {
            const cartitems = {
                items:items
            }
            const token = getUserToken();
            const res =  await axios.post('/web/get-cart-products',cartitems,{
                headers: {
                  'Authorization': `Bearer ${token}` 
                }
              });
              setCartItems(res.data.products);
              setProducts(res.data.products);
              setShipping(res.data.shipping);
             
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    if(cartItems.length === 0 || cartItems.length === undefined)
    {
        navigate('/')
    }
    const getTotalPrice = () =>{
        if(cartItems.length > 0)
        {
            const Totalsum = cartItems.reduce((Total, value) => {
                return Total + (parseFloat(value.price) * parseInt(value.quantity))
              }, 0);
              TotalCartSum = Totalsum;
              let sortedObjs = cartItems.sort((a, b) => a.id > b.id ? 1 : -1);
                cartItems = sortedObjs;
            

        }
    }
    getTotalPrice();
    let isCash = false;
    if(cartItems.length > 0)
    {
        for(let i = 0;i<cartItems.length;i++)
        {
            if(cartItems[i].is_cash == true)
            {
                isCash = true;
            }
        } 
    }
    const handelAddress = (e) =>
    {
        setinputAddress(e.target.value)
    }
    const handelPaymentMethod = async (event) =>{
        const methodValue = event.target.value;
        let shipment = others;
        setMethod(methodValue);
        setPaymentType(methodValue);
        let new_total = TotalCartSum + shipment;
        get_free_shipping(methodValue);
        if(methodValue === 'Cash On Delivery')
        {
            if(shipping != 0)
            {
                setShipping(cash);
            }
            
            setNumberHide('d-none');
            shipment = cash;
            
            
        }else{
            if(shipping != 0)
            {
                setShipping(others);
            }
            
            setNumberHide('d-block');
            
            
        }
        
    }
    const get_free_shipping = async (type) =>{
        try {
            const shipping_content = {
                items:cartItems,
                type:type,
                total:TotalCartSum
            }
            
            const res = await axios.post(`/web/free-shipping`,shipping_content);
            if(shipping != 0)
            {
                if(res.data.charge === true)
                {
                    setShipping(0)
                    if(type === 'Cash On Delivery')
                    {
                        
                        notofication_forcash('warning','Alert!!',`Cash on delivery তে বই হাতে পেয়ে ${(TotalCartSum + 0) - discount} টাকা পরিশোধ করবেন। তবে আমরা আপনাকে কল করে Confirm হয়ে তার পর বই পাঠাবো। Full Address বক্সে আপনার বাসা পর্যন্ত যাওয়ার জন্য পূর্ণাঙ্গ ঠিকানা লিখে দিন। মনে রাখবেন, Delivery Man আপনাকে কল করে না পেলে কিন্তু আপনাকে বই দিতে পারবেনা। বইগুলো রিটার্ন চলে আসবে।`);
                    }else{
                        notofication_forcash('warning','Notice!',`01787943429 Personal নাম্বারে ${(TotalCartSum + 0) - discount} টাকা ${type} এ send money করুন। Send Money করার পর Payment Number বক্সে আপনার ${type} Number দিন। বই হাতে পাবেন ২-৩ দিনের মধ্যে।`);
                    }
                }else{
                    if(type === 'Cash On Delivery')
                    {
                        setShipping(cash);
                        
                        notofication_forcash('warning','Alert!!',`Cash on delivery তে বই হাতে পেয়ে ${(TotalCartSum + cash) - discount} টাকা পরিশোধ করবেন। তবে আমরা আপনাকে কল করে Confirm হয়ে তার পর বই পাঠাবো। Full Address বক্সে আপনার বাসা পর্যন্ত যাওয়ার জন্য পূর্ণাঙ্গ ঠিকানা লিখে দিন। মনে রাখবেন, Delivery Man আপনাকে কল করে না পেলে কিন্তু আপনাকে বই দিতে পারবেনা। বইগুলো রিটার্ন চলে আসবে।`);
                    }else{
                        setShipping(others);
                        notofication_forcash('warning','Notice!',`01787943429 Personal নাম্বারে ${(TotalCartSum + others) - discount} টাকা ${type} এ send money করুন। Send Money করার পর Payment Number বক্সে আপনার ${type} Number দিন। বই হাতে পাবেন ২-৩ দিনের মধ্যে।`);
                    }
                }
            }else{
                if(type === 'Cash On Delivery')
                {
                    notofication_forcash('warning','Alert!!',`Cash on delivery তে বই হাতে পেয়ে ${(TotalCartSum + 0) - discount} টাকা পরিশোধ করবেন। তবে আমরা আপনাকে কল করে Confirm হয়ে তার পর বই পাঠাবো। Full Address বক্সে আপনার বাসা পর্যন্ত যাওয়ার জন্য পূর্ণাঙ্গ ঠিকানা লিখে দিন। মনে রাখবেন, Delivery Man আপনাকে কল করে না পেলে কিন্তু আপনাকে বই দিতে পারবেনা। বইগুলো রিটার্ন চলে আসবে।`);
                }else{
                    notofication_forcash('warning','Notice!',`01787943429 Personal নাম্বারে ${(TotalCartSum + 0) - discount} টাকা ${type} এ send money করুন। Send Money করার পর Payment Number বক্সে আপনার ${type} Number দিন। বই হাতে পাবেন ২-৩ দিনের মধ্যে।`);
                }
            }
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    const handelApplyButton = async () => {
        setIsApply(true);
        setOrderLoading(true);
        try {
            const checkoutData = 
            {
                items : cartItems,
                total: TotalCartSum,
                coupon:coupon
            }
            
            const token = getUserToken();
            const res =  await axios.post('/web/discount',checkoutData,{
                headers: {
                  'Authorization': `Bearer ${token}` 
                }
              });
              setIsApply(false);
            setOrderLoading(false);
              setDiscount(res.data.discount);
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    const handelCoupon = (event) =>{
        setCoupon(event.target.value);
    }
    const handelFormSubmit = async (e) =>{
            e.preventDefault();
            setOrderLoading(true);
            setErrors([]);

            let orderItem = [];
            for(let i = 0;i<cartItems.length;i++)
            {
                if(cartItems[i].quantity > 0)
                {
                    let newItem = {
                        product_id:cartItems[i].id,
                        quantity:cartItems[i].quantity
                    }
                    orderItem.push(newItem)
                }
            }
            // orderItem = cartItems.map(item => {
            //     let newItem = {
            //         product_id:item.id,
            //         quantity:item.quantity
            //     }
            //     return newItem;
            // });
    
            if(orderItem)
            {
                let payment_number = e.target.payment_number.value;
                let full_address = e.target.full_address.value;
                if(e.target.payment_method.value == 'Cash On Delivery')
                {
                    payment_number = '';
                    if(full_address == '')
                    {
                        notofication_forcash('warning','Alert!!','Full Address বক্সে আপনার পূর্ণ ঠিকানা দিতে হবে। আপনি শহরে থাকলে আপনার বাসা নং, রোড নং লিখুন। গ্রামে থাকলে আপনার ইউনিয়ন,গ্রাম লিখুন।');
                        setOrderLoading(false);
                        return false; 
                    }
                }else{
                    if(!payment_number)
                    {
                        alertMessage('error','Error','Payment Number is required!');
                        setOrderLoading(false);
                        return false;
                    }
                }
                let paymentInfo = {
                    product_info: orderItem,
                    name:e.target.name.value,
                    number:e.target.number.value,
                    address:e.target.full_address.value,
                    // address:inputAddress,
                    payment_type:e.target.payment_method.value,
                    payment_number:payment_number,
                    amount:(TotalCartSum + shipping) - discount,
                    // district:district,
                    district:e.target.district.value,
                    area:e.target.sub_district.value,
                    // note:e.target.note.value,
                    coupon:coupon,
                    discount:discount,
                    delivery_charge:shipping
                };
                const token = getUserToken();
                try {
                    const res =  await axios.post('/web/order',paymentInfo,{
                        headers: {
                          'Authorization': `Bearer ${token}` 
                        }
                      });
                      setOrderLoading(false);
                    if(res.data.error === false)
                    {
                        removeCartItem();
                        alertHtmlMessage('success','Success',res.data.message);
                        navigate('/my-order');
                    }else{
                        let errorMessageHml = '';
                        const errorMessages = res.data.message;
                        console.log(errorMessages);
                        
                        errorMessages.forEach((errorItem, index) => {
                            errorMessageHml += `<p class="text-danger text-center m-0">${errorItem}</p>`;
                        });
                        alertHtmlMessage('error','Error',errorMessageHml)
                        // setErrors(res.data.message);
                    }
                } catch (error) {
                    alertMessage('error','Error',error.message)
                }
                setOrderLoading(false);
            }else{
                alertMessage('error','Error','Please Fillup all field!');
                setOrderLoading(false);
            }
    }
    if(products.length == 0)
    {
        return <Loader/>
    }
    return (
        <Container>
            <BreadcrumbComponent breadcrumbs={breadcrumbs} />
            <div className="checkout-section">
                <Row>
                    <Col md={8}>
                        <div className="shipping-form">
                            <Form onSubmit={handelFormSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Label>Name 
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Control type="text" defaultValue={userInfo.name} name="name" placeholder="Full Name" />
                                            <Form.Text className="text-danger error-text error_name">
                                                
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="phone">
                                            <Form.Label>Mobile Number
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Control type="text" defaultValue={userInfo.number} name="number" placeholder="Mobile Number" />
                                            <Form.Text className="text-danger error-text error_number"></Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="zilla">
                                            <Form.Label>Zilla/District
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Select name="district" id="district" onChange={handelArea}>
                                                <option value="">Select District</option>
                                                {
                                                    districts.map((district) => {return(<option key={district.id} value={district.id}>{district.district}</option>)})
                                                }
                                                
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="sub_district">
                                            <Form.Label>Area/Upazila/Sub District
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Select name="sub_district" id="sub_district">
                                                <option value="">Select Area</option>
                                                {
                                                    areas.map((area) => {return(<option key={area.id} value={area.sub_district}>{area.sub_district}</option>)})
                                                }
                                                
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="full_address">
                                            <Form.Label>Full Address 
                                                
                                            </Form.Label>
                                            <Form.Control type="text"  name="full_address" placeholder="Full Address" />
                                            <Form.Text className="text-danger error-text error_full_address">
                                                
                                            </Form.Text>
                                        </Form.Group>  
                                    </Col>
                                    {/* <Col md={6}>
                                        <Form.Group className="mb-3" controlId="address">
                                            <Form.Label>Order Note
                                                
                                            </Form.Label>
                                            <Form.Control as="textarea" name="note" placeholder="Your note" />
                                            <Form.Text className="text-danger error-text error_note"></Form.Text>
                                        </Form.Group>
                                    </Col> */}
                                    
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Payment Method
                                                <span title="this field is required" className='text-danger'>*</span> 
                                            </Form.Label>
                                            <Form.Select name="payment_method" onChange={handelPaymentMethod}>
                                                <option value="">Select Payment Method</option>
                                                <option value="Bkash">Bkash</option>
                                                <option value="Nagad">Nagad</option>
                                                <option value="Rocket">Rocket</option>
                                                {
                                                    isCash ? ''
                                                    : <option value="Cash On Delivery">Cash On Delivery</option>
                                                }
                                                
                                            </Form.Select>
                                            <Form.Text className="text-danger error-text error_payment_type"></Form.Text>
                                            {
                                                method == 'Bkash' || method == 'Nagad' || method == 'Rocket' ?
                                                <p className='payment-bold'>
                                                    {
                                                        method != 'Payment' && method != ''? 
                                                        `01787943429 Personal নাম্বারে ${(TotalCartSum + shipping) - discount} টাকা ${method} এ send money করুন। Send Money করার পর নিচের বক্সে আপনার ${method} Number দিন।`
                                                        :
                                                        `01787943429 Personal নাম্বারে ${(TotalCartSum + shipping) - discount} টাকা send money করুন।`
                                                    }
                                                </p>
                                                :
                                                ''
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="amount" >
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control type="text" readOnly name="amount" value={(TotalCartSum + shipping) - discount} placeholder="Amount" />
                                            <Form.Text className="text-danger error-text error_amount"></Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className={`mb-3 ${numberHide}`} controlId="payment_number">
                                            <Form.Label>Payment Number</Form.Label>
                                            <Form.Control type="text" name="payment_number" placeholder={`Your ${method} Number`} />
                                            <Form.Text className="text-danger error-text error_payment_number"></Form.Text>
                                        </Form.Group>
                                    </Col>
                                    
                                </Row>
                                {
                                    numberHide === 'd-none' ? 
                                    <div>
                                        <Alert variant="warning">
                                        Cash on delivery তে বই হাতে পেয়ে {(TotalCartSum + shipping) - discount} টাকা পরিশোধ করবেন। তবে আমরা আপনাকে কল করে Confirm হয়ে তার পর বই পাঠাবো। Full Address বক্সে আপনার বাসা পর্যন্ত যাওয়ার জন্য পূর্ণাঙ্গ ঠিকানা লিখে দিন। মনে রাখবেন, Delivery Man আপনাকে কল করে না পেলে কিন্তু আপনাকে বই দিতে পারবেনা। বইগুলো রিটার্ন চলে আসবে।
                                        </Alert>
                                    </div>
                                    :
                                    <div>
                                        <Alert variant="warning">
                                        01787943429 Personal নাম্বারে {(TotalCartSum + shipping) - discount} টাকা send money করার পর place order বাটনে ক্লিক করুন। Send Money না করে Place Order বাটনে Click করলে আপনার Order টি Cancel হয়ে যাবে।
                                        </Alert>
                                    </div>
                                }
                                {
                                    
                                    errors.length > 0 ? 
                                    <div className="alert alert-warning" role="alert">
                                        {errors.map((errorItem,index) => {return(<p key={index} className="text-danger text-start m-0">{errorItem}</p>)})}
                                    </div>
                                    : ''
                                }
                                <div className='placeorder_btn text-end'>
                                    {
                                        orderLoading ? 
                                        <Button className="py-2 px-3" variant="primary" type="button">
                                            <Loader/>
                                        </Button>
                                        : 
                                        <div className='placeorder_btn_div'>
                                            <Button className="py-2 px-3" variant="primary" type="submit" >
                                                Place Order
                                            </Button>
                                        </div>
                                    }
                                </div>
                                
                            </Form>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='checkout-summary'>
                            <h3>Checkout Summary</h3>
                            <div className="summary-content">
                                <div className='summery-item'>
                                    <span>SubTotal</span>
                                    <span>{TotalCartSum}Tk</span>
                                </div>
                                <div className='summery-item'>
                                    <span title="Delivery charge will changable by payment method.">Delivery Charge
                                        <div style={{"fontSize": '14px'}}>(Changeable)</div>
                                    </span>
                                    <span>{shipping}Tk</span>
                                </div>
                                {
                                    discount > 0 ?
                                    <div className='summery-item'>
                                        <span>Discount</span>
                                        <span>-{discount}Tk</span>
                                    </div>
                                    :
                                    ''
                                }
                                
                                <div className='summery-item'>
                                    <span>Total</span>
                                    <span>{(TotalCartSum + shipping) - discount}Tk</span>
                                </div>
                                <h5 className='mt-3'>Apply Coupon</h5>
                                <div>
                                    <input type="text" className='form-control' placeholder='Enter coupon code' onChange={handelCoupon} name="coupon" id="coupon"/>
                                    <div>আপনার কাছে কুপন না থাকলে এখানে কিছু লিখতে হবে না ।</div>
                                    <div className='text-end'>
                                        {
                                            isApply ?
                                            <Button className="py-2 px-3" variant="primary" type="button">
                                                <Loader/>
                                            </Button>
                                            :
                                            <button type="button" onClick={handelApplyButton} className='btn btn-primary btn-sm mt-1'>Apply</button>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Successfull</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        Thank You. Your order has been received.Your order id is 3.15.You will received the books by courier service within 3-5 days.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Receipt
                        </Button>
                        <Button variant="success"><Link className='modal-btn' to="/my-order">My All Order</Link></Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Container>
    );
};
export default Checkout;