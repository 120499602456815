import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { alertMessage } from '../../../Functions/Functions';
import Loader from '../../../Loader/Loader';
import { getUserToken } from '../../../Session/Session';
import axios from '../../../Config/axios';
import { Col, Container, Row } from 'react-bootstrap';
import './MyPaidExamTitle.css';
import BreadcrumbComponent from '../../../BreadcrumbComponent/BreadcrumbComponent';
const MyPaidExamTitle = () => {
    const [titles,setTitles]      = useState([]);
    const [isTitles, setIsTitles] = useState(true);
    const navigate              = useNavigate();
    const { id }                = useParams();
    const token                 = getUserToken();
    const [breadcrumbs,setBreadcrumbs]  = useState([
        {name : 'হোম',"url" : '/',active:false},
        {name : 'আমার পেইড পরীক্ষা',"url" : '/my-paid-exams',active:false},
    ]);
    const getTitles = async () =>{
        if(token !== false)
        {
            try {
                const res = await axios.get(`/web/my-paid-exam-title/${id}`,{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                  if(res.data.error == false)
                  {
                    setBreadcrumbs([
                        ...breadcrumbs,
                        {name : res.data.data.exam_name,"url" : '#',active:true},
                    ]);
                      setTitles(res.data.data.titles);
                  }else{
                    alertMessage('error','Error',res.data.message);
                  }
                  
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }else{
            navigate('/login');
        }
        setIsTitles(false);
    }
    useEffect(() => {
        document.title = "Paid Exam | Nahid24.com";
        getTitles();
    },[]);
    const handelTopicPage = (id) => {
        navigate(`/my-paid-exam-topics/${id}`);
    }
    if (isTitles || !titles) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    } 
    return (
        <Container>
            <h2 className="text-center my-2 page-title">আমার পেইড পরীক্ষা</h2>
            <BreadcrumbComponent breadcrumbs={breadcrumbs} />
            {
                titles.length > 0 ?
                    titles.map((title) => {return (
                        <div className="paid-exam-title-section" key={title.id}>
                            <div className='paid-exam-title'>
                                <h2 className="text-center my-2 page-title">{title.title}</h2>
                                <Row>
                                    {
                                        title.subjects.map((subject) => { return(
                                        <Col xs={12} md={4} key={subject.id}>
                                            <div className="subject-box" onClick={() => handelTopicPage(subject.id)}>
                                                <Link to={`/my-paid-exam-topics/${subject.id}`}>{subject.name}</Link>
                                            </div>
                                        </Col>
                                        )})
                                    }
                                    
                                </Row>
                            </div>
                        </div>
                    )}) 
                :
                <div className="alert alert-warning text-center mt-3" role="alert">
                    Data not found!
                </div>  
            }
            
        </Container>
    );
};

export default MyPaidExamTitle;