import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { getUserToken } from '../../../Session/Session';
import axios from '../../../Config/axios';
import './PaidExamModuleResult.css';
import { alertMessage } from '../../../Functions/Functions';
import Loader from '../../../Loader/Loader';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../../../BreadcrumbComponent/BreadcrumbComponent';
const PaidExamModuleResult = () => {
    const [answer,setAnswer]          = useState({});
    const [isAnswer, setIsAnswer]     = useState(true);
    const navigate                      = useNavigate();
    const token                         = getUserToken();
    const { id }                        = useParams();
    const [breadcrumbs,setBreadcrumbs]  = useState([
        {name : 'হোম',"url" : '/',active:false},
        {name : 'আমার পেইড পরীক্ষা',"url" : '/my-paid-exams',active:false},
        {name : 'আমার পেইড পরীক্ষা রেজাল্ট',"url" : '#',active:true},
    ]);
    const getAnswer = async () =>{
        if(token !== false)
        {
            try {
                const res = await axios.get(`/web/paid-exam-module-results/${id}`,{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                 
                if(res.data.error === false)
                {
                    setAnswer(JSON.parse(res.data.data.answers));
                    setIsAnswer(false);
                }else{
                    setIsAnswer(false);
                    alertMessage('error','Error',res.data.message);
                }
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }else{
            navigate('/login');
        }
    }
    useEffect(() => {
        document.title = "Paid Exam Module Result | Nahid24.com";
        getAnswer();
    },[]);
    if(isAnswer) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
        );
    }
    return (
        <Container className='my-2'>
            <BreadcrumbComponent breadcrumbs={breadcrumbs} />
            <div className="paid-exam-module-section">
                {
                    answer.length > 0 ? 
                    <>
                        <div className="paid-exam-result-topbar">
                            <span>Review Answer</span>
                        </div>
                        <div className="question-section mt-4">
                            <Row>
                                {
                                    answer.map((question,index) => {
                                        return(
                                        <Col md={12} className="mb-3" key={index}>
                                            <div className="question-box" id={`question-box-${question.id}`}>
                                                <div className='question-title'>
                                                    {index + 1}. {parse(question.question)}
                                                </div>
                                                <div className='ps-2 options-box'>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-2">
                                                                <input type="checkbox" className="btn-check" />
                                                                <label className={`question-label ${question.given == 1 ? (question.answer == question.given ? 'currect-answer' : 'wrong-answer')  : (question.answer == 1 ? 'currect-answer' : '')} `}>{parse(question.option_one)}</label>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-2">
                                                                <input type="checkbox" className="btn-check" />
                                                                <label className={`question-label ${question.given == 2 ? (question.answer == question.given ? 'currect-answer' : 'wrong-answer')  : (question.answer == 2 ? 'currect-answer' : '')} `} >{parse(question.option_two)}</label>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-2">
                                                                <input type="checkbox" className="btn-check" />
                                                                <label className={`question-label ${question.given == 3 ? (question.answer == question.given ? 'currect-answer' : 'wrong-answer')  : (question.answer == 3 ? 'currect-answer' : '')} `} >{parse(question.option_three)}</label>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-2">
                                                                <input type="checkbox" className="btn-check"/>
                                                                <label className={`question-label ${question.given == 4 ? (question.answer == question.given ? 'currect-answer' : 'wrong-answer')  : (question.answer == 4 ? 'currect-answer' : '')} `} >{parse(question.option_four)}</label>
                                                            </div>
                                                        </Col>
                                                        {
                                                        question.explanation ? 
                                                            <Col md={12}>
                                                                <div className='explanation-box'>
                                                                    {parse(question.explanation)}
                                                                </div>
                                                            </Col>
                                                            : ''
                                                        }
                                                    </Row>
                                                    
                                                </div>
                                            </div>
                                        </Col>
                                        
                                    )})
                                }
                                
                            </Row>
                        </div> 
                    </>
                :
                    <div class="alert alert-warning text-center" role="alert">
                        Data not found!
                    </div>
                }
            </div>
        </Container>
    );
};

export default PaidExamModuleResult;