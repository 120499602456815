import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { alertMessage } from '../../../Functions/Functions';
import Loader from '../../../Loader/Loader';
import axios from '../../../Config/axios';
import { getUserToken } from '../../../Session/Session';
import { Col, Container, Row } from 'react-bootstrap';
import quizIcon  from '../../../../images/1.png';
import ReviewIcon  from '../../../../images/2.png';
import leaderIcon  from '../../../../images/4.png';
import homeIcon  from '../../../../images/5.png';
import './MyPaidExamScore.css';
const MyPaidExamScore = () => {
    const [answer,setAnswer]          = useState([]);
    const [module,setModule]          = useState({});  
    const [isAnswer, setIsAnswer]     = useState(true);
    const navigate                    = useNavigate();
    const token                       = getUserToken();
    const { id }                      = useParams();

    const getAnswer = async () =>{
        if(token !== false)
        {
            try {
                const res = await axios.get(`/web/paid-exam-module-results/${id}`,{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                 
                if(res.data.error === false)
                {
                    setModule(res.data.data);
                    setAnswer(JSON.parse(res.data.data.answers));
                    setIsAnswer(false);
                }else{
                    setIsAnswer(false);
                    alertMessage('error','Error',res.data.message);
                }
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }else{
            navigate('/login');
        }
    }
    useEffect(() => {
        document.title = "Paid Exam Module Score | Nahid24.com";
        getAnswer();
    },[]);
    if(isAnswer) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    }
    return (
        <Container>
            <div className='score-section'>
                {
                    answer.length > 0 ? 
                    <>
                        <div className='obtain-score'>
                            <div className='obtain-parent-section'>
                                <div className="obtain-content-section">
                                    <span className="obtain-title">Your Score</span>
                                    <span className="obtain-marks">{module.obtained_marks} / {module.total_marks}</span>
                                </div>
                            </div>
                            <div className="marks-detail-content">
                                <div className="marks-items">
                                    <div className="marks-item">
                                        <span className="text-warning font-23">{answer.length}</span>
                                        <span>Total Question</span>
                                    </div>
                                    <div className="marks-item">
                                        <span className="text-success font-23">{module.currect_answer} X {module.total_marks / answer.length} = {module.currect_answer * (module.total_marks / answer.length)} </span>
                                        <span>Currect Answer</span>
                                    </div>
                                    <div className="marks-item">
                                        <span className="text-danger font-23">{module.wrong_answer}</span>
                                        <span>Wrong Answer</span>
                                    </div>
                                    <div className="marks-item">
                                        <span className="text-info font-23">{module.wrong_answer} X {module.negative_marks} = {module.wrong_answer * module.negative_marks}</span>
                                        <span>Negative Mark</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="score-button-section">
                            <Row>
                                <Col md={3} xs={6} className="mb-3">
                                    <div className="score-button-item">
                                        <span><Link to={`/paid-exam/${module.exam_topic_id}`}><img src={quizIcon} /></Link></span>
                                        <span>Exam Again</span>
                                    </div>
                                </Col>
                                <Col md={3} xs={6} className="mb-3">
                                    <div className="score-button-item">
                                        <span><Link to={`/paid-exam-result/${id}`}><img src={ReviewIcon} /></Link></span>
                                        <span>Review Answer</span>
                                    </div>
                                </Col>
                                <Col md={3} xs={6} className="mb-3">
                                    <div className="score-button-item">
                                        <span><Link to={`/my-paid-exam-leader-board/${id}`}><img src={leaderIcon} /></Link></span>
                                        <span>Leaderboard</span>
                                    </div>
                                </Col>
                                <Col md={3} xs={6} className="mb-3">
                                    <div className="score-button-item">
                                        <span><Link to={`/my-paid-exam-leader-board-all/${id}`}><img src={leaderIcon} /></Link></span>
                                        <span>Leaderboard All</span>
                                    </div>
                                </Col>
                                <Col md={3} xs={6} className="mb-3">
                                    <div className="score-button-item">
                                        <span><Link to={`/`}><img src={homeIcon} /></Link></span>
                                        <span>Home</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    <div class="alert alert-warning text-center" role="alert">
                        Data not found!
                    </div>
                }
            </div>
        </Container>
    );
};

export default MyPaidExamScore;