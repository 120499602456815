import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import BreadcrumbComponent from '../../BreadcrumbComponent/BreadcrumbComponent';
import axios from '../../Config/axios';
import { alertMessage } from '../../Functions/Functions';
import Loader from '../../Loader/Loader';
import { getUserToken } from '../../Session/Session';
import PaidExamName from './PaidExamName/PaidExamName';
import './PaidExamNames.css';
const PaidExamNames = () => {
    const { id } = useParams();
    const [names,setNames]              = useState([]);
    const [admittedIds,setAdmittedIds]  = useState([]);
    const [admittedStatusIds,setAdmittedStatusIds]  = useState([]);
    const [isNames, setIsNames]         = useState(true);
    const [category, setCategory]       = useState({});
    const token                         = getUserToken();
    const [breadcrumbs,setBreadcrumbs]  = useState([
        {name : 'হোম',"url" : '/',active:false},
        {name : 'পেইড পরীক্ষা',"url" : '/paid-exam-category',active:false},
    ]);
    const getNames = async (id) =>{
        try {
            const res = await axios.get(`/web/paid-exam/names/${id}`,{
                headers: {
                  'Authorization': `Bearer ${token}` 
                }
              });
            setAdmittedIds(res.data.data.admitted_ids);
            setAdmittedStatusIds(res.data.data.user_admitted_status);
            setNames(res.data.data.names);
            setCategory(res.data.data.category);
            setBreadcrumbs([
                ...breadcrumbs,
                {name : res.data.data.category.title,"url" : '#',active:true},
            ]);
            setIsNames(false);
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    useEffect(() => {
        getNames(id);
    },[]);
    if (isNames) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    }
    return (
        <Container>
            <h2 className="text-center my-2 page-title">{category.title}</h2>
            <BreadcrumbComponent breadcrumbs={breadcrumbs} />
            <Row className="mt-4">
                {
                    names.length > 0 ? 
                    names.map((name) => {return(<PaidExamName admittedStatusIds={admittedStatusIds} admittedIds={admittedIds} key={name.id} name={name} />)})
                    : 
                    <Col md={12} >
                        <div className="alert alert-warning text-center" role="alert">
                            Data not found!
                        </div>
                    </Col>
                }
            </Row>
        </Container>
    );
};

export default PaidExamNames;