import React from 'react';
import './NewsCovers.css';
import { Container } from 'react-bootstrap';
import NewsSlider from './NewsSlider/NewsSlider';
const NewsCovers = () => {
    
    return (
        <Container>
            <div className="newscover-section">
                <div className='newscover-content text-center'>
                    <h3>গণমাধ্যমে Nahid24</h3>
                    <p>দৈনিক সংবাদপত্র, টেলিভিশন চ্যানেলসহ  বিভিন্ন গণমাধ্যমে <b>Nahid24</b> সম্পর্কে প্রকাশিত</p>
                    <p>বিভিন্ন সংবাদ ও প্রতিবেদনসমূহ</p>
                </div>
                <NewsSlider/>
            </div>
        </Container>
    );
};

export default NewsCovers;