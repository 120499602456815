import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './NewsSlider.css';
import dailySunLogo  from '../../../../news/logo1.png';
import dailyObserverbdLogo  from '../../../../news/logo2.jpg';
import dailyObserverbdLogoA  from '../../../../news/logo2a.jpg';
import midDayLogo  from '../../../../news/logo3.png';
import midDayLogoA  from '../../../../news/logo3a.png';
import rTvLogo  from '../../../../news/logo4.jpg';
import rTvLogoA  from '../../../../news/logo4a.png';
import banglanews24Logo  from '../../../../news/logo5.png';
import banglanews24LogoA  from '../../../../news/logo5a.png';
import jagonews24Logo  from '../../../../news/logo6.png';
import jagonews24LogoA  from '../../../../news/logo6a.png';
import dailyasianageLogo  from '../../../../news/logo7.png';
import dailyasianageLogoA  from '../../../../news/logo7a.png';
import bdJournalLogo  from '../../../../news/logo8.png';
import bdJournalLogoA  from '../../../../news/logo8a.jpg';
import bartabazarLogo  from '../../../../news/logo9.png';
import bartabazarLogoA  from '../../../../news/logo9a.jpg';
import { Link } from 'react-router-dom';
const NewsSlider = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
        <div className='news-slider-section'>
            <Slider {...settings}>
                <div>
                  <div className="news-content">
                    <a href="https://www.daily-sun.com/printversion/details/616201/Edtech-platform-%E2%80%98Nahid24%E2%80%99">
                        <div className="img-box">
                          <img src={dailySunLogo} alt="Edtech platform ‘Nahid24’" />
                        </div>
                        <p className="news-title">Edtech platform ‘Nahid24’</p>
                        <div className="publish-date">
                          <span className="news-icon-image">
                            <img src={dailySunLogo} alt="Edtech platform ‘Nahid24’" />
                          </span>
                          <span className="date-show">18 April, 2022</span>
                        </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="news-content">
                    <a href="https://www.observerbd.com/news.php?id=361936">
                        <div className="img-box">
                          <img src={dailyObserverbdLogoA} alt="Nahid’s educational platform benefits 200,000 members" />
                        </div>
                        <p className="news-title">Nahid’s educational platform benefits 200,000 members</p>
                        <div className="publish-date">
                          <span className="news-icon-image">
                            <img src={dailyObserverbdLogo} alt="Nahid’s educational platform benefits 200,000 members" />
                          </span>
                          <span className="date-show">16 April, 2022</span>
                        </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="news-content">
                    <a href="https://www.mid-day.com/brand-media/article/nahid-hasan-munnas--education-life-books-and-educational-app-nahid24-23240485">
                        <div className="img-box">
                          <img src={midDayLogoA} alt="Nahid Hasan Munna's Education life, Books and Educational App - Nahid24" />
                        </div>
                        <p className="news-title">Nahid Hasan Munna's Education life, Books and Educational App - Nahid24</p>
                        <div className="publish-date">
                          <span className="news-icon-image">
                            <img src={midDayLogo} alt="Nahid Hasan Munna's Education life, Books and Educational App - Nahid24" />
                          </span>
                          <span className="date-show">11 August,2022</span>
                        </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="news-content">
                    <a href="https://www.rtvonline.com/others/172913/%E0%A6%A8%E0%A6%BE%E0%A6%B9%E0%A6%BF%E0%A6%A6-%E0%A6%B9%E0%A6%BE%E0%A6%B8%E0%A6%BE%E0%A6%A8-%E0%A6%8F%E0%A6%96%E0%A6%A8-%E0%A6%AE%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%9C%E0%A6%BF%E0%A6%95-%E0%A6%B8%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%B0">
                        <div className="img-box">
                          <img src={rTvLogoA} alt="নাহিদ হাসান এখন ‘ম্যাজিক স্যার’" />
                        </div>
                        <p className="news-title">নাহিদ হাসান এখন ‘ম্যাজিক স্যার’</p>
                        <div className="publish-date">
                          <span className="news-icon-image">
                            <img src={rTvLogo} alt="নাহিদ হাসান এখন ‘ম্যাজিক স্যার’" />
                          </span>
                          <span className="date-show">06 April, 2022</span>
                        </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="news-content">
                    <a href="https://www.banglanews24.com/national/news/bd/853718.details">
                        <div className="img-box">
                          <img src={banglanews24LogoA} alt="লেখক হিসেবে মুন্নার পরিচিতির পেছনে হাজারো শিক্ষার্থীর অবদান" />
                        </div>
                        <p className="news-title">লেখক হিসেবে মুন্নার পরিচিতির পেছনে হাজারো শিক্ষার্থীর অবদান</p>
                        <div className="publish-date">
                          <span className="news-icon-image">
                            <img src={banglanews24Logo} alt="লেখক হিসেবে মুন্নার পরিচিতির পেছনে হাজারো শিক্ষার্থীর অবদান" />
                          </span>
                          <span className="date-show">23 April, 2021</span>
                        </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="news-content">
                    <a href="https://www.jagonews24.com/education/news/750593">
                        <div className="img-box">
                          <img src={jagonews24Logo} alt="শিক্ষার্থীদের ‘ম্যাজিক স্যার’ নাহিদ হাসান মুন্না" />
                        </div>
                        <p className="news-title">শিক্ষার্থীদের ‘ম্যাজিক স্যার’ নাহিদ হাসান মুন্না</p>
                        <div className="publish-date">
                          <span className="news-icon-image">
                            <img src={jagonews24LogoA} alt="শিক্ষার্থীদের ‘ম্যাজিক স্যার’ নাহিদ হাসান মুন্না" />
                          </span>
                          <span className="date-show">31 March, 2022</span>
                        </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="news-content">
                    <a href="https://dailyasianage.com/news/284857/nahid-hasan-munna-is-a-passionate-educator-writer-founder-and-ceo-of-nahid24">
                        <div className="img-box">
                          <img src={dailyasianageLogo} alt="Nahid Hasan Munna is a passionate educator, writer, founder, and CEO of Nahid24" />
                        </div>
                        <p className="news-title">Nahid Hasan Munna is a passionate educator, writer, founder, and CEO of Nahid24</p>
                        <div className="publish-date">
                          <span className="news-icon-image">
                            <img src={dailyasianageLogoA} alt="Nahid Hasan Munna is a passionate educator, writer, founder, and CEO of Nahid24" />
                          </span>
                          <span className="date-show">12 April, 2022</span>
                        </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="news-content">
                    <a href="https://www.bd-journal.com/other/196933/%E0%A6%A4%E0%A6%B0%E0%A7%81%E0%A6%A3-%E0%A6%B2%E0%A7%87%E0%A6%96%E0%A6%95-%E0%A6%A8%E0%A6%BE%E0%A6%B9%E0%A6%BF%E0%A6%A6-%E0%A6%B9%E0%A6%BE%E0%A6%B8%E0%A6%BE%E0%A6%A8-%E0%A6%AE%E0%A7%81%E0%A6%A8%E0%A7%8D%E0%A6%A8%E0%A6%BE-%E0%A6%93-%E0%A6%A4%E0%A6%BE%E0%A6%B0-%E0%A6%A8%E0%A6%A4%E0%A7%81%E0%A6%A8-%E0%A6%AF%E0%A6%BE%E0%A6%A4%E0%A7%8D%E0%A6%B0%E0%A6%BE">
                        <div className="img-box">
                          <img src={bdJournalLogo} alt="শিক্ষার্থীদের প্রিয় শিক্ষক ‘নাহিদ হাসান’" />
                        </div>
                        <p className="news-title">শিক্ষার্থীদের প্রিয় শিক্ষক ‘নাহিদ হাসান’</p>
                        <div className="publish-date">
                          <span className="news-icon-image">
                            <img src={bdJournalLogoA} alt="শিক্ষার্থীদের প্রিয় শিক্ষক ‘নাহিদ হাসান’" />
                          </span>
                          <span className="date-show">29 March, 2022</span>
                        </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="news-content">
                    <Link to="https://bartabazar.com/archives/383761" target="_blank">
                        <div className="img-box">
                            <img src={bartabazarLogo} alt="শিক্ষার্থীদের প্রিয় শিক্ষক ‘নাহিদ হাসান’" />
                          </div>
                          <p className="news-title">শিক্ষার্থীদের প্রিয় শিক্ষক ‘নাহিদ হাসান’</p>
                          <div className="publish-date">
                            <span className="news-icon-image">
                              <img src={bartabazarLogoA} alt="শিক্ষার্থীদের প্রিয় শিক্ষক ‘নাহিদ হাসান’" />
                            </span>
                            <span className="date-show">21 April, 2022</span>
                          </div>
                    </Link>
                  </div>
                </div>
            </Slider>
        </div>
    );
};

export default NewsSlider;