import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { getUserInfo, getUserToken } from '../Session/Session';
import '../Profile/Profile.css'
import YouTubePlayer from '../YouTubeEmbed/YouTubeEmbed';
const CourseVideoPlayer = () => {
    const userInfo                  = getUserInfo();
    const [errors, setErrors]       = useState([]);
    const [isLoading, setLoading]   = useState(false);
    const navigate                  = useNavigate();

    useEffect(() => {
        // Disable right-click
        document.addEventListener('contextmenu', (e) => e.preventDefault());
        
        // Disable copy, cut, and paste
        document.addEventListener('copy', (e) => e.preventDefault());
        document.addEventListener('cut', (e) => e.preventDefault());
        document.addEventListener('paste', (e) => e.preventDefault());
        
        // Disable inspect element and other shortcuts
        document.addEventListener('keydown', (e) => {
          if (
            e.key === 'F12' || 
            (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J')) || 
            (e.ctrlKey && e.key === 'U')
          ) {
            e.preventDefault();
          }
        });
    
        // Cleanup listeners on unmount
        return () => {
          document.removeEventListener('contextmenu', (e) => e.preventDefault());
          document.removeEventListener('copy', (e) => e.preventDefault());
          document.removeEventListener('cut', (e) => e.preventDefault());
          document.removeEventListener('paste', (e) => e.preventDefault());
          document.removeEventListener('keydown', (e) => e.preventDefault());
        };
      }, []);
      
    useEffect(()=>
    {
        document.title = "Profile | Nahid24.com"
        if(getUserToken() === false)
        {
            navigate('/login');
        }
    },[])

    return (
        <Container>
            <div className='register-section'>
                <h2 className="register-title mt-3"><span>Video Player</span></h2>
                <div className='register-from'>
                    <Row className="justify-content-center p-2">
                        <Col md={6}>
                            <div className="register-form-content">
                                <YouTubePlayer videoId="FL1DIcdzFEI" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    );
};

export default CourseVideoPlayer;