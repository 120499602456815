import React, { useEffect, useState } from 'react';
import './Book.css';
import { Button, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { getCartItems, updateCartItem } from '../../../Session/Session';
import { alertMessage, createCartObject } from '../../../Functions/Functions';
const Book = ({book}) => {
    const { id, name, photo, price,slug,offer_price,is_offer } = book;
    const navigate                  = useNavigate();
    const [cartItems,setCartItems]  = useState([]);
    useEffect(()=>{
        setCartItems(getCartItems());
    },[]);
    const handelAddToCart = (book) =>{
        const existItems = getCartItems();
        if (existItems) {
            const itemExist = existItems.find(item => item.id === book.id);
            if(itemExist)
            {
                alertMessage('error','Error','You already added!')
            }else{
                let cart = [];
                cart = [...existItems, createCartObject(book,1)];
                updateCartItem(cart);
                alertMessage('success','Success','You have successfully add to cart.');
                navigate('/');
            }
        } else {
            
            updateCartItem([createCartObject(book,1)]);
            alertMessage('success','Success','You Have Successfully Add To Cart.')
            navigate('/');
        }
    }
    return (
        <Col xs={6} md={3} className="book-container">
            <div className='book'>
                <Link to={`/book/${slug}`}>
                    <img src={photo} alt={name}/>
                </Link>
                <Link to={`/book/${slug}`}><h3>{name}</h3></Link>
                
                <div className='product-price-cointainer'>
                    {
                        offer_price > 0 && is_offer == 1 ? 
                            <>
                            <span><strike>৳ {price}</strike></span>
                            <span>৳ {offer_price}</span>
                            </>
                        : 
                        <span>৳ {price}</span>
                    }
                    
                    
                </div>
                {
                    book.stock == 0 ? 
                    <Button type="button"   variant='danger'>Out Of Stock</Button>
                    :
                    <Button type="button"  onClick={() => handelAddToCart(book)}  variant='primary'>Add To Cart</Button>
                }
                
            </div>
        </Col>
    );
};

export default Book;