import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import axios from '../../Config/axios';
import logo  from '../../../images/logo.png';
import { alertMessage } from '../../Functions/Functions';
import { getUserToken } from '../../Session/Session';
import signature  from '../../../images/signature.png';
import Moment from 'moment';
const PaidExamOrderReceipt = () => {
    const [order,setOrder]      = useState({});
    const [isOrder, setIsOrder] = useState(true);
    const navigate              = useNavigate();
    const { id }                = useParams();

    const getOrder = async () =>{
        if(getUserToken() !== false)
        {
            try {
                const token = getUserToken();
                const res = await axios.get(`/web/paid-exam-order/receipt/${id}`,{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                setOrder(res.data.data);
                setIsOrder(false);
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }else{
            navigate('/login');
        }
    }
    useEffect(() => {
        document.title = "Order Receipt | Nahid24.com";
        getOrder();
    },[]);
    if (isOrder || !order) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    } 
    console.log(order);
    return (
        <Container>
            <div className="receipt-container my-2">
                <Row>
                    <Col md={10} className="m-auto">
                    <div className="receipt-section">
                    <header className="clearfix">
                        <div id="logo">
                            <img src={logo}   />
                        </div>
                        <div id="invoice">
                            <h1>INVOICE</h1>
                            <div className="to">ORDER NO: {id}</div>
                            <div className="status">Order Status: <strong>{order.order_status}</strong></div>
                            <div className="date">Invoice Date: {Moment().format('DD/MM/YYYY')}</div>
                        </div>
                    </header>
                    <main >
                        <div id="details" className='clearfix'>
                            <div id="company">
                                <h2 className="name">Sender</h2>
                                <div>Nahid24 Publications</div>
                                <div>Meherchandi, Koroitola</div>
                                <div>Behind Rajshahi University</div>
                                <div>Rajshahi</div>
                                <div>01787943429</div>
                                <div><a href="nahid24.com">nahid24.com</a></div>
                            </div>
                            <div id="client">
                                <h2 className="name">Receiver</h2>
                                <div className="address">Name           : {order.user_name}</div>
                                <div className="address">Full Address   : {order.address}</div>
                                <div className="address">Area/Upazila   : {order.upazila}</div>
                                <div className="address">Zila/District  : {order.district}</div>
                                <div className="address">Contact        : {order.number}</div>
                            </div>
                        </div>
                        <div className='table-responsive'>
                            <table border="0" cellSpacing="0" cellPadding="0">
                                <thead>
                                <tr>
                                    <th className="desc">ITEM DESCRIPTION</th>
                                    <th className="unit">RATE</th>
                                    <th className="total">AMOUNT</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        <tr>
                                        <td className="desc">{order.product.course.name}</td>
                                        <td className="unit">{order.product.amount}</td>
                                        <td className="total">{order.product.amount}</td>
                                    </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td className='title-bold'>TOTAL</td>
                                        <td className="text-center">{order.amount} TK</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div id="details" className="clearfix publisher-info">
                            <div id="company">
                                <div id="thanks">Thank you!</div>
                            </div>
                            <div id="client">
                                <div className='margin-left'>
                                    <img src={signature} className="signature" />
                                    <hr/>
                                    <p className='M-0'>Publisher, Nahid24</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="notice-box">
                            <p id="notices">TERMS & CONDITIONS/NOTES:</p>
                            <div className="notice">You will receive the books by courier service within 3-5 working days. Remember,Sold products are not refundable. If the books is not collected from the courier on time, the book is returned. </div>
                        </div> */}
                    </main>
                    <footer className="bottom">Mehercandi, Koroitola, Rajshahi</footer>
                </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default PaidExamOrderReceipt;