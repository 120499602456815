import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './VideoTutorial.css';
const VideoTutorial = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='videotutorial'>
                            <Row className="videoContent m-0">
                                <Col md={6}>
                                    <div className='video'>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/5IRl5eeQCTE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='guid-content'>
                                        <h2>কিভাবে বই অর্ডার করবেন?</h2>
                                        <p>নাহিদ স্যারের বই কিভাবে অর্ডার করবেন </p>
                                        <p>তা বুঝতে পারছেন না?</p>
                                        <p>তাহলে পাশের Video Tutorial টি দেখতে পারেন। </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default VideoTutorial;