import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import './Register.css';
import axios from '../../Config/axios';
import logo  from '../../../images/Nahid24-Logo-made-by-me-ai-2.png';
import signUp  from '../../../images/sign-up.png';
import { Link, useNavigate } from 'react-router-dom';
import {alertHtmlMessage, alertMessage } from '../../Functions/Functions';
import { setUserInfo, setUserToken } from '../../Session/Session';
import googleLogo  from '../../../images/sign-in-with-google.png';
import Loader from '../../Loader/Loader';
import { getAuth, signInWithPopup ,GoogleAuthProvider } from "firebase/auth";
import initializeFirebase from '../../../Firebase/Firebase.InitConfige';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
initializeFirebase();
const Register = () => {
    const [errors, setErrors]                   = useState([]);
    const [registerLoading, setRegisterLoading] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const [passType,setPassType]                = useState('password');
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();
    useEffect(() => {
        document.title = "Sign Up | Nahid24.com"
     }, []);
     const checkUserLog = async (newUser,loadingFunction) =>{
        try {
            const res = await axios.post('/web/check-user',newUser);
            if(res.data.error === false)
            {
                setUserInfo(res.data.data.info);
                setUserToken(res.data.data.token);
                alertMessage('success','Success',res.data.message);
                navigate('/checkout');
                loadingFunction(false);
            }else{
                let errorMessageHml = '';
                const errorMessages = res.data.message;
                errorMessages.forEach((errorItem, index) => {
                    errorMessageHml += `<p class="text-danger text-center m-0">${errorItem}</p>`;
                });
                alertHtmlMessage('error','Error',errorMessageHml)
                // setErrors(res.data.message);
                loadingFunction(false);
            }
        } catch (error) {
            alertMessage('error','Error',error.message)
            loadingFunction(false);
        }
    }
    const handelRegistration = async (e) =>{
        e.preventDefault();
        setRegisterLoading(true);
        setErrors('');
        
        try {
            const user = {
                name:e.target.name.value,
                email:e.target.email.value,
                number:e.target.number.value,
                password:e.target.password.value,
            };
            const res = await axios.post('/web/register',user);
            if(res.data.error === false)
            {
                setUserInfo(res.data.data.info);
                setUserToken(res.data.data.token);
                setRegisterLoading(false);
                alertMessage('success','Success',res.data.message);
                navigate('/checkout');
            }else{
                let errorMessageHml = '';
                const errorMessages = res.data.message;
                console.log(errorMessages);
                
                errorMessages.forEach((errorItem, index) => {
                    errorMessageHml += `<p class="text-danger text-center m-0">${errorItem}</p>`;
                });
                alertHtmlMessage('error','Error',errorMessageHml)
                // setErrors(res.data.message);
                setRegisterLoading(false);
            }
            
        } catch (error) {
            alertMessage('error','Error',error.message)
            setRegisterLoading(false);
        }
    }
    const handelGoogleSignin = async (e) =>{
        e.preventDefault();
        setGoogleLoading(true);
        try{
            const auth = getAuth();
            const googleLogin =  signInWithPopup(auth, provider)
             .then((result) => {
                 const user = result.user;
                 const provider     = user.providerData[0].providerId;
                 const providerId   = user.providerData[0].uid;
                 const newUser = {
                     name:user.displayName,
                     email:user.email,
                     number:user.phoneNumber,
                     image:user.photoURL,
                     provider:provider,
                     providerId:providerId,
                 }
                 checkUserLog(newUser,setGoogleLoading);
             })
        } catch (error) {
            alertMessage('error','Error',error.message)
            setGoogleLoading(false);
        }
    }
    const handelPasType = () =>{
        if(passType == 'password')
        {
            setPassType('text');
        }else{
            setPassType('password');
        }
    }
    return (
        <Container>
            <div className='register-section'>
                <div className='register-from'>
                    <Row className="justify-content-center align-items-center">
                        <Col className="commont-shadow" md={6}>
                            <div className="title-logo">
                                <img src={signUp} />
                            </div>
                            <div className="register-form-content">
                                <div className="register-image">
                                    <img
                                        src={logo}
                                        width="80"
                                        className="d-inline-block align-top"
                                        alt="React Bootstrap logo"
                                        />
                                </div>
                                <p>
                                    Sign Up করার জন্য নিচে আপনার নাম, ইমেইল, মোবাইল নাম্বার এবং আপনার পছন্দের ৬ ডিজিটের একটি পাসওয়ার্ড দিয়ে Sign Up বাটনে ক্লিক করলেই আপনার একটি একাউন্ট তৈরি হয়ে যাবে। তারপর আপনি Sign In করতে পারবেন।
                                </p>
                                <Form className="mt-5" onSubmit={handelRegistration}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label >Name
                                            <span className="text-danger" title="this field is required">*</span>
                                        </Form.Label>
                                        <Form.Control  name="name" type="text" placeholder="Enter Your Name" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label >Email
                                            <span className="text-danger" title="this field is required">*</span>
                                        </Form.Label>
                                        <Form.Control  name="email" type="email" placeholder="Enter Your Email" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="phone">
                                        <Form.Label >Phone Number
                                            <span className="text-danger" title="this field is required">*</span>
                                        </Form.Label>
                                        <Form.Control  name="number" type="text" placeholder="Enter Your Mobile Number" />
                                    </Form.Group>
                                    <Form.Group className="mb-3 p-relative" controlId="password">
                                        <Form.Label >Password
                                            <span className="text-danger" title="this field is required">*</span>
                                        </Form.Label>
                                        <Form.Control  name="password" title="Password must be at least 6 characters" type={passType} placeholder="Password" />
                                        <span className="password-eye" onClick={handelPasType}><FontAwesomeIcon icon={faEye} /></span>
                                    </Form.Group>
                                    {
                                        errors.length > 0 ? 
                                        <div className="alert alert-warning" role="alert">
                                            {errors.map((errorItem,index) => {return(<p key={index} className="text-danger text-start m-0">{errorItem}</p>)})}
                                        </div>
                                        : ''
                                    }
                                    <div className='text-end'>
                                        {
                                            registerLoading ? 
                                            <Button variant="primary" type="button">
                                                <Loader/>
                                            </Button>
                                            :
                                            <Button variant="primary" type="submit">
                                                Sign Up
                                            </Button>
                                        }
                                    </div>
                                </Form>
                            </div>
                            <div>
                                <p className="signup-link">Already have an account? <Link to="/login">Sign In</Link></p>
                            </div>
                            <div className="signin-with">or Sign in with</div>
                            <div className="signin-other">
                                {
                                    googleLoading ?
                                    <Button variant="primary" type="button">
                                        <Loader/>
                                    </Button>
                                    :
                                    <Button className="other-btn" onClick={handelGoogleSignin}>
                                        <img src={googleLogo} />
                                    </Button>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='videotutorial'>
                <Row className="videoContent">
                    <Col md={6}>
                        <div className='video'>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/rQxRAqkMVEs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='guid-content'>
                            <h2>কিভাবে একাউন্ট খুলবেন?</h2>
                            <p>কিভাবে একাউন্ট খুলবেন তা বুঝতে পারছেন না?</p>
                            <p>তাহলে পাশের Video Tutorial টি দেখতে পারেন। </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default Register;