import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './BreadcrumbComponent.css';
const BreadcrumbComponent = ({breadcrumbs}) => {
    return (
        <div className="breadcrumb-section">
            <Breadcrumb>

            {
                breadcrumbs.map((item,index) => {
                    if(item.active === true)
                    {
                        return(<li key={index} className="breadcrumb-item active" aria-current="page">{item.name}</li>)
                    }else{
                        return(<li key={index} className="breadcrumb-item">
                        <Link to={item.url}>{item.name}</Link>
                    </li>)
                    }
                })
            }
            </Breadcrumb>
        </div>
    );
};

export default BreadcrumbComponent;