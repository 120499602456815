import React, { useEffect, useState } from 'react';
import axios from '../Config/axios';
import { Carousel } from 'react-bootstrap';
import Loader from '../Loader/Loader';
const Banner = () => {
    const [index, setIndex] = useState(0);
    const [sliders,setSliders] = useState([]);
    const [error,setError] = useState(false);
    const [isSliders, setIsSliders] = useState(true);
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
    const getSliders = async () =>{
        try {
            const res = await axios.get('/sliders');
            setSliders(res.data.data);
            setIsSliders(false);
        } catch (error) {
            setError(error.message);
        }
    }
    
    useEffect(() => {
        getSliders();
    },[]);
    if (isSliders) {
        return (<Loader/>);
    }
    return (
        <Carousel variant="dark" indicators={true} activeIndex={index} onSelect={handleSelect}>
            {sliders.length> 0 && sliders.map((slider, index) =>{ 
            return(         
                <Carousel.Item key={slider.id}>
                    <img
                        className="d-block w-100"
                        src={slider.img}
                        alt="First slide"
                    />  
                </Carousel.Item>    
                ) 
            })} 
            
        </Carousel>
    );
};

export default Banner;