import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { alertMessage } from '../../Functions/Functions';
import axios from '../../Config/axios';
import { getUserToken, removeUserToken } from '../../Session/Session';
import Loader from '../../Loader/Loader';
import { getAuth, signOut } from "firebase/auth";
const Settings = () => {

    const firebaseLogout = () =>{
        const auth = getAuth();
        signOut(auth).then(() => {
        // Sign-out successful.
        }).catch((error) => {
        // An error happened.
        });
    }
    const [show, setShow] = useState(false);
    const navigate                  = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [is_loading,setLoading] = useState(false);
    const handelDelete = async () =>{
        setLoading(true);
        const token = getUserToken();
        try {
            const res =  await axios.get('/web/delete-account',{
                headers: {
                    'Authorization': `Bearer ${token}` 
                }
                });
                setLoading(false);
            if(res.data.error === false)
            {
                setShow(false);
                firebaseLogout();
                removeUserToken();
                navigate('/login');
            }else{
                alertMessage('error','Error',res.data.message)
            }
        } catch (error) {
            alertMessage('error','Error',error.message)
        }
    }
    return (
        <Container>
            <div className='register-section delete-account'>
                <h2 className="register-title mt-3"><span>Delete Your Account</span></h2>
                <div className='register-from'>
                    <Row className="justify-content-center p-2">
                        <Col md={3} className="text-center">
                            <div><Link to="/my-order" className='profile-button btn btn-primary d-block mb-2'>My All Order</Link></div>
                            <div><Link to="/my-paid-exams" className='profile-button btn btn-primary  d-block mb-2'>My Paid Exam</Link></div>
                            <div><Link to="/my-paid-exam-results" className='profile-button btn btn-primary  d-block mb-2'>My Paid Exam Result</Link></div>
                            <div><Link to="/delete-account" className='profile-button btn btn-primary  d-block mb-2'>Delete Account</Link></div>
                            <div><Link to="/request-to-remove-data" className='profile-button btn btn-primary  d-block mb-2'>Request To Remove Data</Link></div>
                        </Col>
                        <Col md={6}>
                            <div className="register-form-content">
                                <div className="register-image">
                                {/* <div><Link to="" className='profile-button btn btn-primary d-block mb-2'>Change Your Password</Link></div> */}
                                <div>
                                    <Link onClick={handleShow} className='profile-button btn btn-danger mb-2'>Delete Your Account</Link></div>
                                </div>
                            </div>
                        </Col>
                        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>Warning!</Modal.Title>
                            </Modal.Header>
                                <Modal.Body className="text-center">
                                    <h2 className='text-danger'>Are you sure?</h2>
                                </Modal.Body>
                            <Modal.Footer>
                                {
                                    is_loading ? 
                                    <Button variant="danger">
                                       <Loader/>
                                    </Button>
                                    
                                    :
                                    <Button variant="danger" onClick={handelDelete}>
                                        Yes
                                    </Button>
                                }
                                {
                                    is_loading ? 
                                    <Button variant="primary">
                                        <Loader/>   
                                    </Button>
                                    :
                                    <Button variant="primary" onClick={handleClose}>
                                        No
                                    </Button>
                                }
                            </Modal.Footer>
                        </Modal>
                    </Row>
                </div>
            </div>
        </Container>
    );
};

export default Settings;