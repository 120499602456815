import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './PaidCategory.css';
const PaidCategory = ({category}) => {
    const {id,image,title} = category;
    return (

        <Col xs={6} md={3} className="mb-4">
            <Link to={`/paid-exam-name/${id}`} className="text-decoration-none category-link">
                <div className="category-box">
                    <img src={image} alt={title}/>
                    <h3>{title}</h3>
                </div>
            </Link>
        </Col>

    );
};

export default PaidCategory;