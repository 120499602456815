import React, { useEffect, useState } from 'react';
import axios from '../Config/axios';
import './BookDetails.css';
import parse from 'html-react-parser';
import Loader from '../Loader/Loader';
import { Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../BreadcrumbComponent/BreadcrumbComponent';
import ReactStars from 'react-stars'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCartItems, getUserToken, updateCartItem } from '../Session/Session';
import { alertHtmlMessage, alertMessage, createCartObject } from '../Functions/Functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStairs, faStar } from '@fortawesome/free-solid-svg-icons';
const BookDetails = () => {
    const navigate                  = useNavigate();
    const [quantity,setQuantity]    = useState(1);
    const [book,setBook]            = useState([]);
    const [error,setError]          = useState(false);
    const [errors,setErrors]          = useState([]);
    const [isBook, setIsBook]       = useState(true);
    const [books,setBooks]          = useState([]);
    const [isBooks, setIsBooks]     = useState(true);
    const [ratingNumber,setRating]  = useState(0);
    const [f_start,setFStar] = useState('<i className="fa-regular fa-star"></i>');
    const [s_start,setSStar] = useState('<i className="fa-regular fa-star"></i>');
    const [t_start,setTStar] = useState('<i className="fa-regular fa-star"></i>');
    const [fo_start,setFOStar] = useState('<i className="fa-regular fa-star"></i>');
    const [fi_start,setFIStar] = useState('<i className="fa-regular fa-star"></i>');
    const [ratingLoading,setRatingLoading]      = useState(false);
    const [comment,setComment] = useState('');
    const [totalRating,setTotalRating] = useState(0);
    const [reviews,setReviews] = useState([]);
    const { slug } = useParams();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const getBooks = async () =>{
        try {
            const res = await axios.get('/web/product-random');
            setBooks(res.data.data);
            setIsBooks(false);
        } catch (error) {
            setError(error.message);
        }
    }
    const getBook = async () =>{
        try {
            const res = await axios.get(`/web/product/detail/${slug}`);
            if(res.data.data.error === false)
            {
                setError(res.data.message);
            }else{
                setTotalRating(res.data.rating);
                setReviews(res.data.reviews);
                setBook(res.data.data);
                setIsBook(false);
            }
        } catch (error) {
            setError(error.message);
        }
    }
    useEffect(() => {
        document.title = "Book Details | Nahid24.com";
        getBook();
        getBooks();
    },[slug]);
    const [breadcrumbs] = useState([
        {name : 'Home',"url" : '/',active:false},
        {name : 'Book Details',"url" : `/product/detail/${book.slug}`,active:true},
    ]);
    const handelIncreaseQuantity = () => {
        let increaseQuantity = quantity + 1;
        setQuantity(increaseQuantity);
    }
    const handelDicreaseQuantity = () => {
        if(quantity > 1)
            setQuantity(quantity - 1);
    }

    const handelAddToCart = (book) => {
        const existItems = getCartItems();
        if (existItems) {
            const itemExist = existItems.find(item => item.id === book.id);
            if(itemExist)
            {
                alertMessage('error','Error','You already added!')
            }else{
                let cart = [];
                cart = [...existItems, createCartObject(book,quantity)];
                updateCartItem(cart);
                alertMessage('success','Success','You have successfully add to cart.');
                navigate('/cart');
            }
        } else {
            
            updateCartItem([createCartObject(book,quantity)]);
            alertMessage('success','Success','You Have Successfully Add To Cart.')
            navigate('/cart');
        }
    }
    
    const handelReview = (number) => {
        setRating(number);
        setFStar('<i className="fa-regular fa-star"></i>');
        setSStar('<i className="fa-regular fa-star"></i>');
        setTStar('<i className="fa-regular fa-star"></i>')
        setFOStar('<i className="fa-regular fa-star"></i>')
        setFIStar('<i className="fa-regular fa-star"></i>')
        if(number == 1)
        {
            setFStar('<i class="fa-solid fa-star"></i>');
        }
        if(number == 2)
        {
            setFStar('<i class="fa-solid fa-star"></i>');
            setSStar('<i class="fa-solid fa-star"></i>');
        }
        if(number == 3)
        {
            setFStar('<i class="fa-solid fa-star"></i>');
            setSStar('<i class="fa-solid fa-star"></i>');
            setTStar('<i class="fa-solid fa-star"></i>')
        }
        if(number == 4)
        {
            setFStar('<i class="fa-solid fa-star"></i>');
            setSStar('<i class="fa-solid fa-star"></i>');
            setTStar('<i class="fa-solid fa-star"></i>')
            setFOStar('<i class="fa-solid fa-star"></i>')
        }
        if(number == 5)
        {
            setFStar('<i class="fa-solid fa-star"></i>');
            setSStar('<i class="fa-solid fa-star"></i>');
            setTStar('<i class="fa-solid fa-star"></i>')
            setFOStar('<i class="fa-solid fa-star"></i>')
            setFIStar('<i class="fa-solid fa-star"></i>')
        }
    }
    const handelRatingSubmit = async (e) =>{
        e.preventDefault();
        setRatingLoading(true);
        setErrors('');
        const token = getUserToken();
        try {
            const comment   = e.target.comment.value;
            const object = {comment:comment,rating:ratingNumber,book_id:book.id};
            const res =  await axios.post('/web/rating',object,{
                headers: {
                  'Authorization': `Bearer ${token}` 
                }
              });
              if(res.data.error === false)
              {
                  alertHtmlMessage('success','Success',res.data.message);
                  window.location.reload(true);
              }else{
                  setErrors(res.data.message);
              }
              setRatingLoading(false);
        } catch (error) {
            alertMessage('error',error.response.statusText,error.message)
            setRatingLoading(false);
        }
    }
    if (isBook || !book || !books) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    } 
     
    return (
        <div>
            <Container>
                <BreadcrumbComponent breadcrumbs={breadcrumbs} />
                <div className="product-detail">
                    <Row>
                    
                        {book ? (
                            <Col md={8}>
                                <Row>
                                    <Col md={6}>
                                        <div className='product-image'>
                                            <img src={book.photo} alt=""/>
                                        </div>
                                    </Col>
                                    <Col md={6} className="product-detail-conetent">
                                        <h1>{book.name}</h1>
                                        <div className='reviews-section'>
                                            <div className='review-star'>
                                                <ReactStars
                                                count={5}
                                                value={parseInt(totalRating)}
                                                size={24}
                                                edit={false}
                                                color2={'#0066DC'} 
                                                readonly/>
                                                <span className="review-count">({reviews.length} Customer reviews)</span>
                                            </div>
                                            <div className='details-price-cointainer'>
                                                    {
                                                        book.offer_price > 0 && book.is_offer ==1 ? 
                                                            <>
                                                                <span><strike>৳ {book.price}</strike></span>
                                                                <span>৳ {book.offer_price}</span>
                                                            </>
                                                        : 
                                                        <span>৳ {book.price}</span>
                                                    }
                                                    
                                                    
                                                </div>
                                            <div className="read-btn">
                                                {
                                                    book.read_some ? <><a className="btn btn-primary btn-sm" target="_blank" href={`https://application.nahid24.com/public/${book.read_some}`}>কিছু পেজ পড়ে দেখুন</a></> : ''
                                                }
                                                {
                                                    book.is_book ? <><a className="btn btn-primary btn-sm" target="_blank" href={book.is_book}>বইটি কেমন তা দেখে নিন</a></> : ''
                                                }
                                                {
                                                    book.evidence ? <>
                                                    <Button variant="primary" className="mb-1 evidance-btn" onClick={handleShow}>
                                                        বইটি থেকে বিগত বছরের কমনের প্রমাণসমূহ
                                                    </Button>
                                                    {/* <a className="btn btn-primary btn-sm" target="_blank" href={`https://application.nahid24.com/public/${book.evidence}`}>বইটি থেকে বিগত বছরের কমনের প্রমাণসমূহ</a> */}
                                                    </> : ''
                                                }
                                                
                                                {
                                                    <Link className="btn btn-primary btn-sm" to='/library'>যে যে লাইব্রেরিতে বইটি পাওয়া যেতে পারে</Link>
                                                    // book.library_list ? <><a className="btn btn-primary btn-sm" target="_blank" href={`https://application.nahid24.com/public/${book.library_list}`}>যে যে লাইব্রেরিতে বইটি পাওয়া যেতে পারে</a></> : ''
                                                }
                                                
                                                {
                                                    <a className="btn btn-primary btn-sm" target="_blank" href="https://www.youtube.com/watch?v=zVXSk59TH-4&t=2s">কিভাবে বই অর্ডার করতে হয়</a>
                                                }
                                            </div>
                                        </div>
                                        <div className='quantity-cart d-flex '>
                                            <div className="quantity-section">
                                                <Button className="quantity-decrease" onClick={() => handelDicreaseQuantity()}>-</Button>
                                                <Button variant="light" className="quantity-btn">{quantity}</Button>
                                                <Button onClick={() => handelIncreaseQuantity()}>+</Button>
                                            </div>
                                            {
                                                book.stock == 0 ? 
                                                    <Button type="button" className="ms-3"  variant='danger'>Out Of Stock</Button>
                                                : 
                                                    <Button className="add-to-cart ms-3" onClick={() => handelAddToCart(book)} variant='primary'>Add To Cart</Button>
                                            }
                                            
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                    <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>বইটি থেকে বিগত বছরের কমনের প্রমাণসমূহ</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {parse(book.evidence)}
                                        </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        </Modal.Footer>
                                    </Modal>
                                        <div className="product-decription p-3">
                                            <h2>Description</h2>
                                            <div>
                                                {parse(book.desc)}
                                            </div>
                                            <div className='review-box'>
                                                {
                                                    getUserToken() ? 

                                                    <>
                                                        <h3>Add Your Review</h3>
                                                        <form onSubmit={handelRatingSubmit}>
                                                            <h5>Your rating</h5>
                                                            <div className='review-rating-container'>
                                                                <span onClick={() => handelReview(1)}>{parse(f_start)}</span>
                                                                <span onClick={() => handelReview(2)}>{parse(s_start)}</span>
                                                                <span onClick={() => handelReview(3)}>{parse(t_start)}</span>
                                                                <span onClick={() => handelReview(4)}>{parse(fo_start)}</span>
                                                                <span onClick={() => handelReview(5)}>{parse(fi_start)}</span>
                                                            </div>
                                                            <h5>Your review</h5>
                                                            <textarea className="form-control" defaultValue={comment} name="comment" rows="3"></textarea>

                                                            {
                                                                errors.length > 0 ? 
                                                                <div className="alert alert-warning" role="alert">
                                                                    {errors.map((errorItem,index) => {return(<p key={index} className="text-danger text-start m-0">{errorItem}</p>)})}
                                                                </div>
                                                                : ''
                                                            }
                                                            {
                                                                    ratingLoading ? 
                                                                    <Button variant="primary" type="button">
                                                                        <Loader/>
                                                                    </Button>
                                                                    :
                                                                    <Button type='submit'>Submit</Button>
                                                            }
                                                            
                                                        </form>
                                                    </> 
                                                    :
                                                    ''
                                                }
                                                
                                                <div className='user-review-section'>
                                                    <h2 className='user-review-section-title'>Review({reviews.length})</h2>
                                                    {
                                                    
                                                    reviews.length > 0 && reviews.map((review, index) =>{
                                                        let star_html = '';
                                                        for (let i = 1; i <= 5; i++) {
                                                            if(parseFloat(review.rating) >= i)
                                                            {
                                                                star_html += '<span><i class="fa-solid fa-star"></i>';
                                                            }else{
                                                                star_html += '<span><i className="fa-regular fa-star"></i></span>';
                                                            }
                                                        }
                                                        return (
                                                            <div className='user-review-content-box' key={index}>
                                                                <div className="review-user-img">
                                                                <span className="review-user"></span>
                                                                </div>
                                                                <div className='review-content'>
                                                                    <div className='user-name-date'>
                                                                        <span className='reviewer-name'>{review.name}</span>
                                                                        <span style={{padding:"0px 5px",fontSize: "18px"}} >-</span>
                                                                        <span className='review-date'> {review.created_at}</span>
                                                                    </div>
                                                                    <div className='user-review-start'>
                                                                         {
                                                                            parse(star_html)
                                                                         }  
                                                                        
                                                                    </div>
                                                                    <div className='review-comment'>{review.comment}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </Col>
                            ) : (
                                <Col md={8}>
                                    <Row>
                                    <Col md={12} className="text-center">
                                        <Alert  variant="warning">
                                            404 Not Found!
                                        </Alert>
                                    </Col>
                                    </Row>
                                </Col>
                            )
                        }
                            
                        <Col md={4}>
                            <div className="side-products">
                                <div className="box">
                                    <h2 className="side-product-title">More Books</h2>
                                </div>
                                {books.length > 0 && books.map((morebook, index) =>{
                                    return(         
                                        <div key={morebook.id} className='box product-box d-flex align-items-center'>
                                            <div className='box-image'>
                                                <Link to={`/book/${morebook.slug}`}><img src={morebook.photo} alt=""/></Link>
                                            </div>
                                            <div className='box-content'>
                                                <h3><Link to={`/book/${morebook.slug}`}>{morebook.name}</Link></h3>
                                                <div className='product-price-cointainer'>
                                                    {
                                                        morebook.offer_price > 0 && morebook.is_offer == 1 ? 
                                                            <>
                                                                <span><strike>৳ {morebook.price}</strike></span>
                                                                <span>৳ {morebook.offer_price}</span>
                                                            </>
                                                        : 
                                                        <span>৳ {morebook.price}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>   
                                        ) 
                                })}
                                
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default BookDetails;