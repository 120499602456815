import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Category from '../Category/Category';
import Books from './Books/Books';
import './Home.css';
import NewsCovers from './NewsCovers/NewsCovers';
import VideoTutorial from './VideoTutorial/VideoTutorial';
const Home = () => {
    useEffect(() => {
        document.title = "Home | Nahid24.com"
     }, []);
    return (
        
        <>
            <Container>
                <Category/>
                <div className="home-section">
                    <h1 className='text-center my-3'>Buy One & make it yours</h1>
                    <Books/>
                </div>
            </Container>
            <VideoTutorial/>
            <NewsCovers/>
        </>
        
    );
};

export default Home;