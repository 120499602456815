import React, { useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import './ContactUs.css';
import contactUs  from '../../../images/contact-us.png';
const ContactUs = () => {
    useEffect(() => {
        document.title = "Contact Us | Nahid24.com"
     }, []);
    return (
        <Container>
            <div className='p-3 bg-white contact-section'>
                <Row>
                    <Col md={6} className="m-auto commont-shadow">
                    <div className='title-logo'>
                        <img src={contactUs} />
                    </div>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Enter Your Name" />
                                <Form.Text className="text-danger">
                                    
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Enter Your Email" />
                                <Form.Text className="text-danger">
                                    
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Enter Your Phone" />
                                <Form.Text className="text-danger">
                                    
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="text" as="textarea" rows={4} placeholder="Enter Your Message" />
                                <Form.Text className="text-danger">
                                    
                                </Form.Text>
                            </Form.Group>
                            <div className='text-end'>
                                <Button variant="primary" type="button">
                                    Send
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default ContactUs;