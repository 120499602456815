import React, { useEffect, useState } from 'react';
import axios from '../Config/axios';
import './Header.css';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo  from '../../images/logo.png';
import cartIcon  from '../../cart-icon.svg';
import Banner from '../Banner/Banner';
import topBanner from '../../images/top-banner.jpg';
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import { getCartItems, getUserToken, removeUserToken } from '../Session/Session';
import { getAuth, signOut } from "firebase/auth";
import Swal from 'sweetalert2';
const Header = () => {
    const navigate                  = useNavigate();
    const location = useLocation();
    const [cartCount,setCartCount]  = useState(0);
    const [notifications,setNotifications]  = useState(0);
    const [isLoading,setIsLoading]  = useState(true);
    const [expanded, setExpanded]   = useState(false);
    const token = getUserToken();

    const getNotifications = async () =>{
        try {
            const res = await axios.get('/web/notification',{
                headers: {
                  'Authorization': `Bearer ${token}` 
                }
              });
            setNotifications(res.data.data.length);
            setIsLoading(false);
        } catch (error) {
            
        }
    }
    
    useEffect(() => {
        if(token){
            getNotifications();
        }
        
    }, [location]);

    useEffect(()=>{
        setCartCount(getCartItems().length ? getCartItems().length : 0);
    },[getCartItems()]);
    const handelLogout = (e) =>{
        e.preventDefault();
        Swal.fire({
            icon: 'warning',
            title: 'Do you want to logout?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
              actions: 'my-actions',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
          }).then((result) => {
            if (result.isConfirmed) {
                firebaseLogout();
                removeUserToken();
                navigate('/login');
            }
          })
    }
    const firebaseLogout = () =>{
        const auth = getAuth();
        signOut(auth).then(() => {
        // Sign-out successful.
        }).catch((error) => {
        // An error happened.
        });
    }

    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("top-banner-img").style.display = "block";
      } else {
        document.getElementById("top-banner-img").style.display = "none";
      }
      prevScrollpos = currentScrollPos;
    }
    
    return (
        
        <>
        
            <div id="book_navbar">
                <a target="_blank" id="top-banner-img" href="https://play.google.com/store/apps/details?id=com.nahid24.android"><img className="top-banner-img" src={topBanner} width="100%" alt="" /></a>
                <Container>
                    <div className='navbar-container'>
                        <Navbar expanded={expanded} bg="light"  expand="lg" className="p-0">
                            <Navbar.Brand to="/" as={Link}>
                                <img
                                    src={logo}
                                    width="80"
                                    className="d-inline-block align-top"
                                    alt="React Bootstrap logo"
                                    />
                            </Navbar.Brand>
                            <div className='toggle-container'>
                                <Link className='nav-link notification-res' to="/my-order">
                                    <i className="fa-solid fa-bell"></i> <sup class="notification_res_number">{notifications}</sup>
                                </Link>
                            <Link className='nav-link cart-icon-res'  to="/cart">
                                <img src={cartIcon} alt="cart icon" />
                                <span className="cart-count-res">{cartCount}</span>
                            </Link >
                            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" />
                            </div>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ms-auto" onClick={() => setExpanded(expanded ? false : "expanded")}>
                                    <Link className='nav-link' to="/">Home</Link >
                                    <Link className='nav-link' to="/about">About us</Link >
                                    <Link className='nav-link' to="/contact">Contact us</Link >
                                    {
                                        getUserToken() ? 
                                        <>
                                            <Link className='nav-link' to="/my-order">My All Order</Link >
                                            <Link className='nav-link' to="/profile">My Profile</Link >
                                            <Link className='nav-link notification' to="/my-order">
                                                <i className="fa-solid fa-bell"></i> <sup class="notification_number">{notifications}</sup>
                                            </Link>
                                            <button className="btn btn-danger btn-sm" onClick={handelLogout} type="button">Logout</button>
                                        </>
                                        :
                                        <>
                                            <Link className='nav-link'  to="/login">Login</Link >
                                            <Link className='nav-link'  to="/register">Sign up</Link >
                                        </>

                                    }
                                    <Link className='nav-link cart-icon'  to="/cart">
                                        <img src={cartIcon} alt="cart icon" />
                                        <span className="cart-count">{cartCount}</span>
                                    </Link >
                                </Nav>
                                {/* <span onClick={() => handelCollapse()} className="navbar-close-btn">X</span> */}
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </Container>
            </div>
            <Container className="slider-section">
                <Banner/>
            </Container>
        </>
        
    );
};

export default Header;