// YouTubePlayer.js
import React, { useEffect, useState, useRef } from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import './YouTubePlayer.css';
const YouTubePlayer = ({ videoId }) => {
  const [isMounted, setIsMounted] = useState(false);
  const playerRef = useRef(null); // Create a ref to access the player instance

  useEffect(() => {
    setIsMounted(true); // Set to true after component mounts
  }, []);

  const handleTimeUpdate = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.currentTime; // Get current playback time
      const duration = playerRef.current.duration; // Get total duration of the video

      // Check if we're 1 second away from the end
      if (duration - currentTime <= 1) {
        playerRef.current.pause(); // Pause the player
      }
    }
  };

  if (!isMounted) return null; // Don't render player until mounted


  return (
    <Plyr
      ref={playerRef} // Attach the ref to the Plyr component
      source={{
        type: 'video',
        sources: [
          {
            src: videoId,
            provider: 'youtube',
          },
        ],
      }}
      options={{
        controls: [
          'play', 'progress', 'current-time', 'mute', 'volume', 'settings',//'fullscreen'
        ],
        youtube: {
          noCookie: true,       // Use YouTube's privacy-enhanced mode
          rel: 0,               // Hide related videos
          modestbranding: 1,    // Minimize YouTube branding
        },
      }}
      onTimeUpdate={handleTimeUpdate} // Listen for the timeupdate event
    />
  );
};

export default YouTubePlayer;
